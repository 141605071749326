// Contains content generated by icomoon.
// INSTRUCTIONS:
//  1) Go to https://icomoon.io/app/#/select
//  2) Click Import Icons, select /src/Website/assets/public/fonts/icomoon/selection.json
//  3) Make whatever changes needed.
//  4) Click Generate Font. Download zip.
//  5) Copy fonts folder to /src/Website/assets/public/fonts/icomoon/fonts.
//  6) Update this file and _fontfamily.scss with contents from generated style.scss, variables.scss, style.css

$icomoon-hash: "dzt4yx";
$icomoon-font-path: "/assets/public/fonts/icomoon/fonts";
$icomoon-font-family: "icomoon";

$icon-arrow-right: "\e900";
$icon-arrow-left: "\e901";
$icon-arrow-down: "\e902";
$icon-arrow-up: "\e903";
$icon-keyboard_arrow_down: "\e905";
$icon-search: "\e906";
$icon-menu: "\e904";
$icon-list: "\e904";
$icon-options: "\e904";
$icon-lines: "\e904";
$icon-hamburger: "\e904";
$icon-home: "\e907";
$icon-sort-amount-desc: "\ea4d";
$icon-cross: "\ea0f";

@font-face {
  font-family: "#{$icomoon-font-family}";
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?#{$icomoon-hash}");
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?#{$icomoon-hash}#iefix")
      format("embedded-opentype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?#{$icomoon-hash}")
      format("truetype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?#{$icomoon-hash}")
      format("woff"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?#{$icomoon-hash}##{$icomoon-font-family}")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@mixin iconStyle() {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icomoon-font-family}" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin icon($name) {
  @include iconStyle();
  @extend %icon-#{$name};
  display: inline-block;
}

[class^="icon-"],
[class*=" icon-"] {
  &:before,
  &:after {
    @include iconStyle();
  }
}

.text-icon {
  vertical-align: middle;
  position: relative;
  top: -1px;
  margin-left: 0.75em;
}

//When including new icons, copy and paste from icomoon below
.icon-arrow-right:before {
  @extend %icon-arrow-right;
}
.icon-arrow-left:before {
  @extend %icon-arrow-left;
}
.icon-arrow-down:before {
  @extend %icon-arrow-down;
}
.icon-arrow-up:before {
  @extend %icon-arrow-up;
}
.icon-keyboard_arrow_down:before {
  @extend %icon-keyboard_arrow_down;
}
.icon-search:before {
  @extend %icon-search;
}
.icon-menu:before {
  @extend %icon-menu;
}
.icon-list:before {
  @extend %icon-list;
}
.icon-options:before {
  @extend %icon-options;
}
.icon-lines:before {
  @extend %icon-lines;
}
.icon-hamburger:before {
  @extend %icon-hamburger;
}
.icon-home:before {
  @extend %icon-home;
}
.icon-sort-amount-desc:before {
  @extend %icon-sort-amount-desc;
}
.icon-cross:before {
  @extend %icon-cross;
}

%icon-arrow-right {
  content: $icon-arrow-right;
}
%icon-arrow-left {
  content: $icon-arrow-left;
}
%icon-arrow-down {
  content: $icon-arrow-down;
}
%icon-arrow-up {
  content: $icon-arrow-up;
}
%icon-keyboard_arrow_down {
  content: $icon-keyboard_arrow_down;
}
%icon-search {
  content: $icon-search;
}
%icon-menu {
  content: $icon-menu;
}
%icon-list {
  content: $icon-list;
}
%icon-options {
  content: $icon-options;
}
%icon-lines {
  content: $icon-lines;
}
%icon-hamburger {
  content: $icon-hamburger;
}
%icon-home {
  content: $icon-home;
}
%icon-sort-amount-desc {
  content: $icon-sort-amount-desc;
}
%icon-cross {
  content: $icon-cross;
}
