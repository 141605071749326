.selectbox {
  @extend .interactive;
  position: relative;
  z-index: 0;
  overflow: hidden;
  background-color: $color-input-back;
  border: 1px solid $color-input-border;
  border-radius: 0;
  padding: 0.5rem 0.75rem;
  @include font-size(sm);

  &:focus {
    background-color: $color-input-back;
    border: 1px solid $color-input-border;
  }
  &:before {
    @include icon("arrow-down");
    @include font-size(md);
    position: absolute;
    color: $link-color;
    right: 0.75em;
    top: 1.1em;
    z-index: 1;
  }
}

.selectbox > select {
  @extend .interactive;
  padding: 0.8em 0.4em;
  min-width: 100%;
  border: none;
  box-shadow: none;
  background: transparent;
  background-image: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  z-index: 2;
  position: relative;

  &:focus {
    outline: none;
  }

  &::-ms-expand {
    display: none;
  }
}
