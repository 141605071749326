.home-header {
  text-align: center;

  .header-section-menu {
    display: none;
  }

  .menu-button {
    position: absolute;
    top: 10px;
    left: 10px;
    text-align: left;
  }

  @include screen($bp-tablet) {
    &.section {
      padding-left: 0;
    }
  }
}