.email-disclaimer {
  $button-size: 32px;

  position: fixed;
  width: 90%;
  max-width: 830px;
  padding: 10px 50px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #f7f7f7;
  z-index: $z-index-modal;
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms ease-in, visibility 300ms linear 300ms;

  .is-active-modal & {
    opacity: 1;
    visibility: visible;
    z-index: $z-index-modal;
    transition: opacity 300ms ease-in, visibility 100ms linear;
  }

  .title {
    @include font-primary;
    font-weight: bold;
    font-size: 28px;
    text-align: center;
    text-transform: uppercase;

    @include screen($bp-tablet) {
      font-size: 24px;
    }
  }

  .copy {
    margin: 18px 0 32px;
    max-height: 52vh;
    overflow: auto;
    font-size: 14px;
  }

  .button-red + .button-red {
    margin-left: 25px;
  }

  .modal-close {
    position: absolute;
    bottom: 98%;
    left: 100%;
    margin-left: 7px;
    margin-bottom: 7px;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    color: #5f5f5f;
    background-color: #cececd;
    text-align: center;
  }

  @include screen($bp-tablet) {
    max-height: 85%;
    padding: 20px 30px;

    .button-red {
      & + .button-red {
        margin-left: 5px;
      }
    }

    .copy {
      height: 40vh;
      overflow: auto;
      font-size: 14px;
    }

    .modal-close {
      left: auto;
      right: 0;
      margin: 0 -15px -5px 0;
    }
  }
}

.modal-bg {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  visibility: hidden;
  opacity: 0;
  transition: opacity 300ms ease-in, visibility 300ms linear 300ms;

  .is-active-modal & {
    opacity: 1;
    visibility: visible;
    z-index: $z-index-modal-bg;
    transition: opacity 300ms ease-in, visibility 100ms linear;
  }
}

.external-window {
  .email-disclaimer {
    $button-size: 32px;

    width: 100%;
    height: 100%;
    padding: 10px 15px;
    background-color: #fdfdfd;
    opacity: 1;
    visibility: visible;
    z-index: $z-index-modal;

    .title {
      font-weight: bold;
      font-size: 30px;
      text-align: center;
      text-transform: uppercase;

      @include screen($bp-tablet) {
        font-size: 24px;
      }
    }

    .actions {
      margin-bottom: 20px;
      margin-top: 20px;
    }
    .button-red + .button-red {
      margin-left: 25px;
    }

    @include screen($bp-tablet) {
      .copy {
        height: auto;
        overflow: auto;
        font-size: 14px;
      }
    }
  }
}
