.page-home {
  min-height: 91vh;
  @include make-row($no-gutter);
  .column-main,
  .column-sidebar {
    @include make-col-ready($no-gutter);
  }
  .column-main {
    @include make-col(9);

    @include media-breakpoint-down(md) {
      @include make-col(12);
    }
  }
  .column-sidebar {
    @include make-col(3);
    @include media-breakpoint-down(md) {
      @include make-col(12);
    }
  }
}
