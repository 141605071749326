.download-prompt-module {
  padding: 0.75em 0;
}

.download-prompt {
  box-sizing: border-box;
  color: rgb(0, 26, 50);
  font-family: "BakerMcKenzieBernini", Arial Narrow, Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 32px;
  outline-color: rgb(0, 26, 50);
  outline-style: none;
  outline-width: 0px;
  padding-top: 2rem;
  padding-left: 56px;
  padding-right: 56px;
  padding-top: 2rem;
  text-align: center;
  text-rendering: optimizelegibility;

  .instructions {
    margin-bottom: 2em;
    font-weight: bold;
  }
  .button-red {
    background-attachment: scroll;
    background-clip: border-box;
    background-color: rgb(173, 18, 42);
    background-image: none;
    background-origin: padding-box;
    background-position: 0% 0%;
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-size: auto;
    border-bottom-color: rgb(255, 255, 255);
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;
    border-bottom-style: none;
    border-bottom-width: 0px;
    border-image-outset: 0;
    border-image-repeat: stretch;
    border-image-slice: 100%;
    border-image-source: none;
    border-image-width: 1;
    border-left-color: rgb(255, 255, 255);
    border-left-style: none;
    border-left-width: 0px;
    border-right-color: rgb(255, 255, 255);
    border-right-style: none;
    border-right-width: 0px;
    border-top-color: rgb(255, 255, 255);
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    border-top-style: none;
    border-top-width: 0px;
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    cursor: pointer;
    display: inline-block;
    font-family: "BakerMcKenzieBernini", Arial Narrow, Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    outline-color: rgb(255, 255, 255);
    outline-style: none;
    outline-width: 0px;
    padding-bottom: 16px;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 16px;
    text-align: center;
    text-rendering: optimizelegibility;
    text-transform: uppercase;
    transition-delay: 0s;
    transition-duration: 0.4s;
    transition-property: box-shadow;
    transition-timing-function: ease;
    vertical-align: baseline;
    -moz-appearance: button;
  }
}
