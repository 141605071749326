.menu-trigger {
  display: inline-block;
  padding: 0 ($spacing/2) 0;
  text-align: center;
  max-height: 100%;

  @include media-breakpoint-down(md) {
    color: $color-brand-light;

    .icon {
      display: block;
      @include font-size(xxl);
    }
    .label {
      display: block;
      position: relative;
      top: -1.2em;
      @include font-size(xxs);
      text-transform: uppercase;
    }
  }
}
