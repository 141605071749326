.portal-grid {
  @include detail-padding;
  .portal-grid-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-right: $grid-spacing * -1;
    list-style: none;
    list-style-type: none;
    padding-left: 0;
  }
  $grid-spacing: 20px;
  .grid-item {
    width: calc(25% - #{$grid-spacing});
    display: inline-block;
    background-color: $color-white;
    text-align: center;
    padding: 20px;
    margin-bottom: $grid-spacing;
    margin-right: $grid-spacing;
    opacity: 0;
    cursor: pointer;
    position: relative;

    a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  $item-padding: 20px;
  .icon-container {
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 0;
      top: 0;
      left: 0;
      transition: height 0.3s ease-in-out;
      z-index: -1;
    }
  }
  .item-icon {
    padding: $item-padding;
    height: 120px;
    position: relative;
    margin-bottom: 20px;
    img {
      @include center("both", "absoulte");

      max-height: 80px;
      max-width: 100%;
    }

    .hover-icon {
      display: none;
    }
  }
  .item-name {
    border-top: solid 1.2px $color-gray-d;
    padding: 16px 0 0 0;
    font-size: px-to-rem(20px);
    font-weight: bold;
    color: $color-gray-4;
    line-height: px-to-rem(26px);

    &.no-icon {
      height: 100%;
      border-top: none;
      min-height: 150px;
      padding-top: 0;
      transition: color 0.3s ease-in-out;
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 0;
        top: 0;
        left: 0;
        transition: height 0.3s ease-in-out;
        z-index: -1;
      }
    }
    span {
      min-height: 50px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  @include screen($bp-tablet) {
    $grid-spacing: 20px;
    .grid-item {
      width: calc(33.333% - #{$grid-spacing});
      padding: 20px;
      margin-bottom: $grid-spacing;
    }
  }
  @include screen($bp-phablet) {
    $grid-spacing: 20px;
    .grid-item {
      width: calc(50% - #{$grid-spacing});
      padding: 20px;
      margin-bottom: $grid-spacing;
    }
  }
  @include screen($bp-mobile) {
    $grid-spacing: 20px;
    .portal-grid-container {
      margin-right: 0;
      padding-left: 20px;
    }
    .grid-item {
      width: 100%;
      padding: 20px;
      margin-bottom: $grid-spacing;
    }
  }
}
html.no-touch {
  .portal-grid {
    .grid-item {
      &:hover {
        .has-hover {
          &:before {
            height: 160px;
          }
          .item-icon {
            .hover-icon {
              display: block;
            }
            .default-icon {
              display: none;
            }
          }
        }
        .no-icon {
          color: $color-white;
          &:before {
            height: 100%;
          }
        }
      }
    }
  }
}
