.site-logo-home {
  display: inline-block;
  width: 200px;
  .no-img {
    background-color: $color-brand-secondary;
    color: $color-brand-light;
    padding: 10px 1em;
    transition: background-color 0.3s linear;

    &:hover {
      background-color: darken($color-brand-secondary, 10%);
      color: $color-brand-light;
      text-decoration: none;
    }
  }

  img {
    width: 100%;
  }

  @include screen($bp-tablet) {
    padding-left: 30px;
  }
  @include screen($bp-phablet) {
    padding-left: 0;
  }
}
