form {
}
.input-container {
  $button-size: 25px;
  $input-padding: 10px;
  background-color: $color-white;
  padding: px-to-rem($input-padding);
  margin: 0 auto;
  text-align: left;
  button {
    background-color: transparent;
    outline: none;
    border: none;
    height: $button-size;
    width: $button-size;
  }
  input[type="text"] {
    outline: none;
    border: none;
    color: $color-black;
    font-size: px-to-rem(12px);
    font-weight: bold;
    //line-height: $button-size;
    width: calc(100% - #{$button-size} - 2 *#{$input-padding});

    @include screen($bp-tablet) {
      font-size: 16px;
    }
  }
}
