.global-menu {
  $menu-side-padding: 40px;
  $item-spacing: 14px;
  $primary-menu-collapsed-width: 60px;
  position: fixed;
  left: -480px;
  top: 0;
  width: $global-menu-width;
  height: 100vh;
  z-index: 1;
  background-color: $color-gray-3;
  color: $color-white;
  overflow: hidden;

  .menu-list-container {
    overflow-y: auto;
    max-height: 75vh;
  }
  .menu-handbook-link {
    padding: 1rem px-to-rem($menu-side-padding);
    display: block;
    a {
      color: $color-white;
      font-size: px-to-rem($fs-lg);
      position: relative;
      display: inline-block;
      &:after {
        @include icon("arrow-right");
        @include center("vertical", "absolute");
        right: -25px;
      }
    }
  }
  .menu-content {
    .top-content {
      display: block;
      height: px-to-rem(40px);
      color: $color-gray-d;
      padding: px-to-rem(20px) px-to-rem($menu-side-padding);
      transition: padding 0.4s ease-in-out;

      .home-logo {
        color: $color-gray-d;
      }
    }
    .menu-title {
      font-size: px-to-rem(31px);
      font-weight: bold;
      display: block;
      margin-bottom: px-to-rem(40px);
      padding: 0 px-to-rem($menu-side-padding);
    }
    .main-content {
      padding: px-to-rem(20px) 0;
    }
    .location-name {
      font-size: px-to-rem(12px);
      color: $color-gray-9;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: px-to-rem($item-spacing);
      padding: 0 px-to-rem($menu-side-padding);
    }
    ul {
      list-style: none;
      padding-left: 0;
      font-size: px-to-rem(18px);
      margin-bottom: px-to-rem(60px);
      &:last-child {
        margin-bottom: 0;
      }
      li.location-item {
        display: block;
        color: $color-white;
        a {
          color: $color-white;
          display: block;
          padding: px-to-rem($item-spacing) px-to-rem($menu-side-padding);
        }
        border-bottom: solid 1px $color-gray-2;
        cursor: pointer;
        transition: background-color 0.2s ease-in-out;
        &:hover,
        &.active-page {
          background-color: lighten($color-gray-3, 10%);
        }
      }
    }
  }
  .icon-home {
    font-size: px-to-rem(26px);
  }
  &.secondary-menu-open {
    .menu-container {
      left: -1 * px-to-rem($primary-menu-collapsed-width);
    }
    .primary-menu {
      .top-content {
        padding-left: px-to-rem($primary-menu-collapsed-width - 45px);
      }
      .main-content {
        visibility: hidden;
        opacity: 0;
      }
    }
  }
  .menu-container {
    overflow: hidden;
    position: relative;
    width: 200%;
    display: flex;
    left: -100%;
    transition: left 0.4s ease-in-out;
  }
  .primary-menu {
    position: relative;
    width: 100%;

    ul {
      li.location-item {
        .active-menu-arrow {
          display: none;
        }
        &.active-page {
          position: relative;
          .active-menu-arrow {
            @include center("vertical", "absolute");
            display: block;
            right: 2px;
            &:before {
              @include icon("keyboard_arrow_down");
              font-size: px-to-rem($fs-xxl);
              transform: rotate(-90deg);
            }
          }
        }
      }
    }
  }
  .secondary-menu {
    position: relative;
    width: 100%;
    background-color: $color-gray-4;
    .top-content {
      color: $color-white;
      span {
        cursor: pointer;
      }
    }
    &.menu-content {
      ul {
        li.location-item {
          &:hover,
          &.active-page {
            background-color: lighten($color-gray-3, 20%);
          }
        }
      }
    }
  }
  .secondary-container {
    width: 100%;
    padding-left: px-to-rem($primary-menu-collapsed-width);
    .main-content {
      display: none;
      &.visible {
        display: block;
        overflow: auto;
      }
    }
    .icon-arrow-left {
      &:before {
        margin-right: 4px;
        position: relative;
        top: 1px;
      }
    }
  }
  @include screen($bp-phablet) {
    width: 80vw;
    left: -80vw;
  }
  @include screenheight(900px) {
    .menu-list-container {
      max-height: 70vh;
    }
  }
  @include screenheight($bp-mobile-portrait) {
    .menu-list-container {
      max-height: 50vh;
    }
  }
}
