.sidebar-sections {
  .section {
    padding: $spacing-tall * 2 $spacing-tall 0 $spacing-tall;

    &.secondary-section {
      padding-top: px-to-rem(60px);
    }
  }

  @include screen($bp-tablet) {
    .section {
      padding-top: $spacing-tall/2;
      padding-bottom: $spacing-tall/2;
      // padding-left: 0;
    }
  }
}

.site-tagline {
  @include font-size(xxxxl);
  margin-bottom: 1em;
}

.site-description {
  @include font-size(sm);
  margin-bottom: 2em;
}
