$option-padding: $fs-lg / 2;
$border-style: 1px solid $color-gray-9;
$border-radius: 3px;
$icon-spacing: 3em;

.autocomplete-suggestions-wrapper {
  position: relative;
  margin: 0 auto;
  width: 50%;

  .autocomplete-suggestions {
    background-color: $color-white;
    padding: 0;
    display: none;
    position: absolute;
    text-rendering: optimizeLegibility;
    overflow-y: auto;
    max-height: 75vh;
    width: 100% !important;
    font-size: 0.85em;
    left: -1px;
    right: 0;
    top: 0;
    border-radius: 0 0 $border-radius $border-radius;
    border-left: $border-style;
    border-right: $border-style;
    border-bottom: $border-style;
    box-shadow: 0 3px 3px 0 rgba(95, 95, 95, 0.6);

    .autocomplete-suggestion {
      list-style-type: none;
      font-size: px-to-rem($fs-typeahead-results);
      color: $color-brand-red-d;
      background-color: $color-white;
      text-align: left;
      font-weight: bold;
      position: relative;
      padding: px-to-rem($option-padding) 1em px-to-rem($option-padding) 1em;
      border-top: $border-style;
      cursor: pointer;

      .label {
        float: none;
        text-transform: none;

        .auto-term {
          font-weight: normal;
        }
      }

      &:hover {
        background-color: $color-brand-red-d;
        color: $color-white;
      }
    }

    .autocomplete-selected {
      background-color: $color-brand-red-d;
      color: $color-white;
    }

    .autocomplete-hint {
      @include font-primary;
      font-weight: bold;
    }
  }

  @include screen($bp-phablet) {
    width: 100%;
  }
}
