/*******************************************
      HELPER UTILITIES
********************************************/
.background-full {
  background-size: cover;
  background-position: center;
  background-color: transparent;
  background-repeat: no-repeat;
}

//use on UL or OL to remove styles
.reset-list {
  @include reset-list;
}

.reset-link {
  text-decoration: none;

  &:hover,
  &:visited,
  &:active,
  &:focus {
    text-decoration: none;
  }
}

.link-block {
  display: block;
}

.no-scrollbar {
  @include no-scrollbar;
}

// .mobile-left {
//   @include screen($bp-phablet) {
//     float: left;
//   }
// }
// .mobile-right {
//   @include screen($bp-phablet) {
//     float: right;
//   }
// }
//
// .mobile-none {
//   @include screen($bp-phablet) {
//     display: none;
//   }
// }
