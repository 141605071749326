.chapter-intro {
  text-align: center;
  @include detail-padding;
  margin-top: px-to-rem(30px);
  padding-bottom: px-to-rem(30px);
  position: relative;
  z-index: 11;
  .chapter-intro-header {
    width: 68%;
    margin: 0 auto;
  }
  h1 {
    font-size: px-to-rem(28px);
    margin-bottom: px-to-rem(20px);
  }
  .drop-down-trigger {
    color: $color-brand-red-d;
    cursor: pointer;
    font-weight: bold;
    outline: none;
    &:after {
      @include icon("keyboard_arrow_down");
      margin-left: 4px;
      position: relative;
      top: 4px;
    }
    &.expanded {
      &:after {
        transform: rotate(180deg);
      }
    }
  }
  $drop-down-grid-spacing: 15px;
  .drop-down-container {
    @include horizontal-align;

    width: 950px;
    background-color: $color-white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    box-shadow: 0 3px 3px 0 rgba(95, 95, 95, 0.5);
    z-index: 5;
    &.active {
      max-height: 1000px;
      transition: max-height 0.5s ease-in-out;
    }

    .drop-down-heading {
      padding: px-to-rem(30px) px-to-rem(30px) px-to-rem(10px) px-to-rem(30px);
      text-align: center;
    }
    .drop-down-list {
      padding: 0 20px 0 20px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      list-style-type: none;
      list-style: none;
    }
    .drop-down-item {
      display: inline-block;
      margin: $drop-down-grid-spacing;
      width: calc(33.3333% - #{$drop-down-grid-spacing * 2});

      a {
        display: block;
        text-align: center;

        &:hover {
          color: $color-brand-red-d;
        }
      }
    }
  }
  .rich-text {
    width: 60%;
    margin: 0 auto;
  }
  @include screen($bp-tablet) {
    .chapter-intro-header {
      width: 75%;
    }
    .rich-text {
      width: 80%;
    }
    .drop-down-container {
      width: 80vw;
    }
  }
  @include screen($bp-phablet) {
    .chapter-intro-header {
      width: 90%;
    }
  }
  @include screen($bp-mobile) {
    .drop-down-container {
      width: 100%;
      &.active {
        max-height: 60vh;
        overflow-y: auto;
      }
      .drop-down-list {
        padding-top: 0;
      }
      .drop-down-item {
        display: block;
        width: 100%;
        margin-top: 1em;
        margin-bottom: 1em;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}
