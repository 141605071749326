/* IE10+ detection */
html[data-agent*="Trident"] {
  .frost-overlay {
    opacity: 0.75;
  }
}
html:not([data-agent*="Trident"]) {
  .frost-overlay {
    &::before {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      @extend .background-full;
      opacity: 0.6;
      content: " ";
      filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='3');
      -webkit-filter: url(#blur-filter);
      filter: url(#blur-filter);
      -webkit-filter: blur(50px);
      filter: blur(50px);
      backface-visibility: hidden;
      perspective: 1000;
      transform: translate3d(0, 0, 0);
      transform: translateZ(0);
    }
  }
}

.locations-section {
  position: relative;
  padding-bottom: $locations-spacing;
  height: 100vh;
  overflow-y: auto;
  @include media-breakpoint-down(lg) {
    height: auto;
  }
  @include media-breakpoint-down(md) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.decor-background {
  @extend .background-full;

  @include media-breakpoint-down(md) {
    background-attachment: fixed;
    background-position: 0 0;
  }
}
.frost-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0.98;
  background-color: rgba($color-white, 1);
}

.locations-section-inner {
  position: relative;
  overflow: hidden;
  @extend .background-full;
  padding: $locations-spacing;
  margin: 0 $locations-spacing * 2 $locations-spacing $locations-spacing * 2;

  @include screen($bp-mobile) {
    margin: 0;
  }
}

.locations {
  position: relative;
  z-index: 2;

  > .locations-title {
    margin-bottom: 2em;
    @include font-size(sm);
    text-transform: uppercase;
    letter-spacing: 0.3em;
    color: $color-gray-4;
  }

  margin-top: $locations-spacing-tall;

  &:first-child {
    margin-top: 0;
  }

  @include media-breakpoint-down(md) {
    > .locations-title {
      text-align: center;
      color: $color-brand-light;
    }
  }
}

.locations {
  .column {
    margin-bottom: $spacing/2;
  }
}
.location {
  $block-height: 200px;
  box-sizing: border-box;
  background-color: $color-brand-light;
  height: $block-height;
  text-align: center;

  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;

  .location-block {
    flex: 1 1 50%;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
  }

  .location-title {
    color: $font-color-header;
    @include font-size(xxl);
    transition: color 0.3s linear;

    @media screen and (max-width: 1200px) and (min-width: 1024px) {
      @include font-size(xl);
      padding: 0 2px;
    }
  }

  .tile-decor {
    position: relative;
    overflow: hidden;

    > * {
      position: relative;
      z-index: 1;
    }

    &:before {
      content: " ";
      position: absolute;
      z-index: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: calc(100% - 5px);
      background-color: $color-brand-light;
      opacity: 1;
      transition: height 0.3s ease-in;
    }
  }

  .tile-border-decor {
    position: relative;

    &:before {
      display: block;
      position: absolute;
      content: " ";
      width: 80%;
      top: 0;
      left: 50%;
      margin-left: -40%;
      border-top: 1px solid $color-gray-d;
      transition: all 0.3s ease-in;
    }
    .wrapper {
      width: 100%;
      height: $block-height / 2;
    }
  }

  .tile-image-block {
    margin: 0 ($spacing * 1.5);
    padding: 5px 0;
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: $block-height / 2;

    .image-wrapper {
      display: inline-block;
      flex-grow: 1;
      flex-basis: 50%;
      align-items: center;

      &:first-child {
        margin-right: 5px;
      }
      &:nth-child(3) {
        margin-left: 5px;
      }
    }
    img {
      display: block;
      max-height: 60px;
      max-width: 100%;
      margin: 0 auto;
    }
  }

  &:hover {
    .location-title {
      color: $color-brand-light;
    }
    .tile-decor {
      &:before {
        height: 0px;
      }
    }

    .tile-border-decor {
      &:before {
        width: 0%;
        top: 10%;
        margin-left: 0%;
      }
    }
  }
} //end location
.columns-3 .column {
  width: 33.3333%;
}
