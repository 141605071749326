.search-top-container {
  .back-to-page {
    color: $color-brand-red-d;
    transition: color 0.3s ease-in-out;
    cursor: pointer;
    font-weight: bold;
    padding: 0 px-to-rem(32px) px-to-rem(10px) px-to-rem(32px);
    display: inline-block;
    &:before {
      @include icon("arrow-left");
      margin-right: 4px;
      position: relative;
      font-weight: bold;
      top: 1px;
    }
    &:hover {
      color: $color-green;
    }
  }
}
.search-filters {
  padding: px-to-rem(10px) px-to-rem(32px);
  font-weight: bold;
  .search-term {
    padding-left: 2px;
    font-size: px-to-rem($fs-lg);
  }
  .close {
    color: $color-brand-red-d;
    transition: color 0.3s ease-in-out;
    display: inline-block;
    margin-left: 2px;
    font-size: px-to-rem(20px);
    cursor: pointer;
    &:hover {
      color: $color-green;
    }
  }
  @include screen($bp-tablet) {
  }
  @include screen($bp-phablet) {
  }
}
