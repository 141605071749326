button {
  background-color: transparent;
  outline: none;
  border: none;
}
@mixin button-transition(
  $hover-color: $color-green,
  $text-color: $color-gray-4,
  $text-hover-color: $color-white
) {
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    background-color: $hover-color;
    color: $text-color;
    transition: height 0.3s ease-in-out;
    z-index: -1;
  }
  &:hover {
    color: $text-hover-color;
    &:before {
      height: 100%;
    }
  }
}
@mixin button($outline: $color-green) {
  font-size: px-to-rem(12px);
  font-weight: bold;
  padding: px-to-rem(20px) px-to-rem(18px);
  transition: color 0.3s ease-in-out;
  position: relative;
  z-index: 2;
  border: solid 1.2px $color-green;
}

.btn-primary {
  @include button();
}

html.no-touch {
  .btn-primary {
    @include button-transition($color-green, $color-gray-4, $color-white);
  }
}
