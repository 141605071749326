.word-cloud {
  background-color: $color-white;
  text-align: center;
  @include detail-padding(80px);
  padding-top: px-to-rem(40px);
  padding-bottom: px-to-rem(40px);
  font-size: px-to-rem(28px);
  color: $color-brand-red-d;
  $defaultFontSize: 32px;
  $animation: 0.4s ease-in-out;
  .tag-container {
    width: 100%;
    margin: 0 auto;
    &.container-active {
      .tag {
        opacity: 0.15;
        transform: scale(0.7);
      }
    }
  }
  .tag {
    white-space: nowrap;
    line-height: $defaultFontSize * 1.5;
    //transition: all $animation;
    display: inline-block;
    padding: 4px 8px;
    cursor: pointer;
    opacity: 0;
    &.initialized {
      opacity: 1;
      transition: all $animation;
    }
  }
  $hover-ratio: 1.4;
  .tag:nth-child(5n + 1) {
    $tagFontSize: px-to-rem($defaultFontSize * 2);
    font-size: $tagFontSize;
  }
  .tag:nth-child(5n + 2) {
    $tagFontSize: px-to-rem($defaultFontSize);
    font-size: $tagFontSize;
  }
  .tag:nth-child(5n + 3) {
    $tagFontSize: px-to-rem($defaultFontSize * 1.4);
    font-size: $tagFontSize;
  }
  .tag:nth-child(5n + 4) {
    $tagFontSize: px-to-rem($defaultFontSize * 0.8);
    font-size: $tagFontSize;
  }
  .tag:nth-child(5n) {
    $tagFontSize: px-to-rem($defaultFontSize * 1.6);
    font-size: $tagFontSize;
  }
  .tag:nth-child(4n) {
    color: $color-brand-yellow;
  }
  .tag:nth-child(4n + 1) {
    color: $color-brand-red-d;
  }
  .tag:nth-child(4n + 2) {
    color: $color-green-a;
  }
  .tag:nth-child(4n + 3) {
    color: $color-blue-purple;
  }
  @include screen($bp-tablet) {
    .tag-container {
      width: 100%;
    }
  }
  @include screen($bp-phablet) {
    $defaultFontSize: 16px;
    .tag:nth-child(5n + 1) {
      font-size: px-to-rem($defaultFontSize * 2);
    }
    .tag:nth-child(5n + 2) {
      font-size: px-to-rem($defaultFontSize);
    }
    .tag:nth-child(5n + 3) {
      font-size: px-to-rem($defaultFontSize * 1.4);
    }
    .tag:nth-child(5n + 4) {
      font-size: px-to-rem($defaultFontSize * 0.8);
    }
    .tag:nth-child(5n) {
      font-size: px-to-rem($defaultFontSize * 1.6);
    }
    .tag {
      line-height: $defaultFontSize * 1.5;
    }
  }
}
html.no-touch {
  .word-cloud {
    .tag-container {
      &.container-active {
        .tag {
          &:hover {
            opacity: 1;
            transform: scale(1.4);
            border-radius: 15px;
            z-index: 3;
          }
        }
      }
    }
    .tag:nth-child(4n) {
      &:hover {
        background-color: $color-brand-yellow;
        color: $color-white;
      }
    }
    .tag:nth-child(4n + 1) {
      &:hover {
        background-color: $color-brand-red-d;
        color: $color-white;
      }
    }
    .tag:nth-child(4n + 2) {
      &:hover {
        background-color: $color-green-a;
        color: $color-white;
      }
    }
    .tag:nth-child(4n + 3) {
      &:hover {
        background-color: $color-blue-purple;
        color: $color-white;
      }
    }
  }
}
