.video-rte {
  position: relative;
  z-index: 2;

  &-iframe {
    height: 400px;
    width: 830px;

    @include media-breakpoint-down(md) {
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      height: 200px;
    }
  }
}
.video-rte + .locations {
  margin-top: 40px;
}
.video-modal {
  opacity: 1;
  visibility: visible;
  z-index: 130;
  -webkit-transition: opacity 0.3s ease-in, visibility 0.1s linear;
  transition: opacity 0.3s ease-in, visibility 0.1s linear;

  position: fixed;
  width: 90%;
  max-width: 830px;
  left: 50%;
  top: 40%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #f7f7f7;
  background-color: transparent;
  -webkit-transition: opacity 0.3s ease-in, visibility 0.3s linear 0.3s;
  transition: opacity 0.3s ease-in, visibility 0.3s linear 0.3s;

  & .modal-close {
    position: absolute;
    top: -1.8em;
    right: 0.4em;
    color: transparent;
    background-color: transparent;
    z-index: 200;
    width: 24px;
    height: 24px;

    @include media-breakpoint-down(md) {
      // color: $color-white;
      right: 0;
      top: -2.5em;
    }
  }

  &::after {
    content: "\D7";
    font-family: icomoon !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    font-size: 4rem;
    position: absolute;
    right: 0;
    top: -0.85em;
    z-index: 1;
    color: rgba($color-gray-9, 1);

    @include media-breakpoint-down(md) {
      // color: $color-white;
      right: -0.1em;
      top: -1em;
    }
  }
}
