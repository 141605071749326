/*******************************************
            SETTINGS
********************************************/
$legacy-support-for-ie: false;

/*******************************************
            PATHS
********************************************/
$path-font: "/assets/public/fonts/";
$path-image: "/assets/public/images/";

/*******************************************
            FONT STARTUP
********************************************/
$font-family-name: "Muli";

/*******************************************
            SPACING
********************************************/
$spacing: 20px;

$page-spacing: ceil($spacing * 3); //60px

$spacing-tall: ceil($spacing * 2); //40px

$grid-spacing: floor($spacing/1.5); //30px
$locations-spacing: $spacing-tall;
$locations-spacing-tall: ceil($spacing * 4); //80px

$heading-bottom-spacing: floor($spacing / 2);

//grid helpers
$no-gutter: (
  xs: 0px,
  sm: 0px,
  //
  md: 0px,
  //
  lg: 0px,
  //
  xl: 0px,
);

/*******************************************
            Z-INDEX
********************************************/
$z-index-header: 100;
$z-index-menu: 110;
$z-index-modal-bg: 120;
$z-index-modal: 130;

/*******************************************
            LAYOUT
********************************************/
$header-height: 100px;
$footer-height: 100px;

$page-side-margins: 80px;
$page-side-margins-tablet: 60px;
$page-side-margins-mobile: 30px;

$detail-section-padding: 80px;
$detail-section-padding-tablet: 60px;
$detail-section-padding-mobile: 30px;

$topic-detail-section-padding: 160px;
$topic-detail-section-padding-tablet: 120px;
$topic-detail-section-padding-mobile: 60px;

/*******************************************
            MENU
********************************************/
$menu-button-size: 60px;
$header-logo-height: 70px;
$header-small-logo-height: 45px;
$menu-mobile-button-size: 52px;
$menu-font-size: 9px;
$line-width: 30px;
$line-height: 2.5px;
$line-gap: 7px;
$header-minimize-factor: 0.8;
$global-menu-width: 480px;
