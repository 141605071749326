.escape-button {
  height: px-to-rem($menu-button-size);
  width: px-to-rem($menu-button-size);
  background-color: $color-white;
  display: inline-block;
  position: relative;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  text-align: left;

  &__red {
    background-color: rgba($color-brand-red-lt, 1);
    top: inherit;

    span.escape-text {
      color: $color-white;
    }

    div.escape-lines-container {
      .escape-x:before {
        color: $color-white;
      }
    }
  }

  .escape-lines-container {
    height: 80%;
    width: 100%;
    position: relative;

    .escape-x {
      display: block;
      height: 100%;

      &:before {
        content: "\D7";
        font-family: icomoon !important;
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        display: inline-block;
        font-size: 4rem;
        position: absolute;
        right: 0.18em;
        bottom: 0.005em;
        z-index: 1;
        color: rgba($color-brand-red-lt, 1);

        @include media-breakpoint-down(md) {
          right: 0.12em;
          bottom: 0.12em;
        }
      }
    }
  }
  .escape-text {
    text-transform: uppercase;
    color: rgba($color-brand-red, 1);
    font-size: px-to-rem(9px);
    font-weight: bold;
    position: absolute;
    bottom: 4px;
    width: 100%;
    text-align: center;
  }

  @include screen($bp-phablet) {
    height: px-to-rem($menu-mobile-button-size);
    width: px-to-rem($menu-mobile-button-size);
    top: 4px;
    right: 10px;
    position: absolute;
    z-index: 10;
    .escape-lines-container {
      height: 100%;
    }
  }
}
@mixin menu-minimized($size) {
  .escape-button {
    $line-height: $line-height * $header-minimize-factor;
    $line-width: $line-width * $header-minimize-factor;
    $line-gap: $line-gap * $header-minimize-factor;

    .escape-lines {
      height: $line-height;
      width: $line-width;
      bottom: -($size/2) + ($line-height/2) + 3px;
      &:before,
      &:after {
        height: $line-height;
        width: $line-width;
      }
      &:before {
        top: -1 * $line-gap;
      }
      &:after {
        bottom: -1 * $line-gap;
      }
      &.close {
        height: 0;
        &:before {
          top: -2px;
        }
        &:after {
          bottom: 0;
        }
      }
    }
    .escape-text {
      font-size: px-to-rem($menu-font-size * $header-minimize-factor);
      bottom: 4px;
    }
  }
}
