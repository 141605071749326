.menu-button {
  height: px-to-rem($menu-button-size);
  width: px-to-rem($menu-button-size);
  background-color: $color-black;
  display: inline-block;
  position: relative;
  cursor: pointer;
  transition: height 0.3s ease-in-out;
  $tranistion-duration: 0.3s;
  $menu-color: $color-white;

  .menu-lines-container {
    height: 80%;
    width: 100%;
    position: relative;
  }
  .menu-lines {
    @include center("both", "relative");
    content: "";
    height: $line-height;
    width: $line-width;
    background-color: $menu-color;
    position: relative;
    display: block;
    transition: all $tranistion-duration ease-in-out;
    cursor: pointer;
    &:before,
    &:after {
      content: "";
      transition: all $tranistion-duration ease-in-out;
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: $menu-color;
    }
    &:before {
      top: -1 * $line-gap;
    }
    &:after {
      bottom: -1 * $line-gap;
    }
    &.close {
      background-color: transparent;
      &:before {
        transform: rotate(45deg);
        top: 0;
        background-color: $menu-color;
      }
      &:after {
        transform: rotate(-45deg);
        bottom: 0;
        background-color: $menu-color;
      }
    }
  }
  .menu-text {
    text-transform: uppercase;
    color: $color-white;
    font-size: px-to-rem(9px);
    font-weight: bold;
    position: absolute;
    bottom: 6px;
    width: 100%;
    text-align: center;
    // left: 50%;
    // transform: translateX(-50%);
  }

  @include screen($bp-phablet) {
    height: px-to-rem($menu-mobile-button-size);
    width: px-to-rem($menu-mobile-button-size);
    .menu-lines-container {
      height: 70%;
    }
  }
}
@mixin menu-minimized($size) {
  .menu-button {
    $line-height: $line-height * $header-minimize-factor;
    $line-width: $line-width * $header-minimize-factor;
    $line-gap: $line-gap * $header-minimize-factor;
    height: px-to-rem($size);
    width: px-to-rem($size);
    .menu-lines {
      height: $line-height;
      width: $line-width;
      bottom: -($size/2) + ($line-height/2) + 3px;
      &:before,
      &:after {
        height: $line-height;
        width: $line-width;
      }
      &:before {
        top: -1 * $line-gap;
      }
      &:after {
        bottom: -1 * $line-gap;
      }
      &.close {
        height: 0;
        &:before {
          top: -2px;
        }
        &:after {
          bottom: 0;
        }
      }
    }
    .menu-text {
      font-size: px-to-rem($menu-font-size * $header-minimize-factor);
      bottom: 4px;
    }
  }
}
