.search-result-container {
  @include detail-padding(20px);
  width: calc(100%);
  background-color: $color-white;
  .search-result {
    display: block;
    padding: px-to-rem(40px) 0;
    border-bottom: solid 1.2px $color-gray-d;
    opacity: 0;
  }
  .result-title {
    color: $color-green;
    font-size: px-to-rem(22px);
    font-weight: bold;
    margin-bottom: 8px;
  }
  .search-tags {
  }
  .tag {
    color: $color-brand-red-d;
    font-weight: bold;
    font-size: px-to-rem(12px);
    + .tag {
      &:before {
        content: "/";
        color: $color-gray-3;
        margin: 0 8px 0 4px;
      }
    }
  }
  .view-more-container {
    text-align: center;
    margin: px-to-rem(30px) 0;
  }

  .no-handbook-search-results {
    font-size: px-to-rem($fs-lg);
    padding: px-to-rem(30px) 0;
  }
}
