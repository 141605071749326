// Subpage-styling
.translation-menu + .print-header + .header + .home-escape {
  top: 140px;
}
.header + .home-escape-desktop {
  position: relative;
  top: 100px;
  width: 100%;
  z-index: 100;

  @include media-breakpoint-down(md) {
    display: block;
    top: 22px;
    text-align: center;

    .home-escape-message {
      position: absolute;
      top: 80px;
      height: 150px;
      flex-direction: column;
      flex-wrap: nowrap;
      padding: 10px 0;
      gap: 8px;

      &-title {
        order: 1;
        width: 100%;
      }
      &-content {
        order: 2;
        margin-top: 5px;
      }
      &-button {
        order: 3;
      }
    }
  }
}
.home-escape {
  height: $locations-spacing * 2;

  &-message {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    color: rgba($color-white, 1);
    background-color: $color-gray-3;
    height: 100%;
    width: 100%;
    padding: 14px 80px 14px 30px;
    gap: 30px;

    @include screen($bp-desktop-wide) {
      margin: 0;
      &-title {
        order: 1;
        width: 60%;
      }
      &-content {
        order: 3;
        margin-top: -50px;
      }
      &-button {
        order: 2;
      }
    }

    &-button {
      background-color: rgba($color-brand-red-lt, 1);
      color: rgba($color-white, 1);
      padding: 6px 36px;
    }

    &.home-escape-message-close {
      display: none;
    }
  }

  &-mobile {
    display: none;

    .home-escape-message {
      position: absolute;
      top: 64px;
      height: 150px;
      flex-direction: column;
      flex-wrap: nowrap;
      padding: 10px 0;
      gap: 8px;

      &-title {
        order: 1;
        width: 100%;
      }
      &-content {
        order: 2;
        margin-top: 5px;
      }
      &-button {
        order: 3;
        margin-top: 8px;
      }

      &:after {
        content: "";
        position: absolute;
        right: 2em;
        top: -0.3em;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid $color-gray-3;
      }
    }
  }

  @include media-breakpoint-down(md) {
    &-mobile {
      display: block;
    }
    &-desktop {
      display: none;
    }
  }
}
