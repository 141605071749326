.hero-section {
  background-color: transparent;
  width: 100%;
  text-align: center;
  padding-top: px-to-rem($header-height);
  .handbook-header-name {
    color: $color-white;
    opacity: 0;
    font-size: px-to-rem($fs-h1);
  }
  .icon-search {
    color: $color-brand-red-d;
    top: 2px;
    position: relative;
  }
  .input-container {
    width: 50%;
  }
  @include screen($bp-phablet) {
    padding-top: px-to-rem($header-height/2);
    .input-container {
      width: 100%;
    }
    .handbook-header-name {
      font-size: px-to-rem($fs-h2);
    }
  }
}
