.page-error {
  .detail-section {
    min-height: 300px;
    padding-top: px-to-rem(40px);
  }
  @include screen($bp-phablet) {
    min-height: 58vh;
  }
  @include screen($bp-mobile) {
    min-height: 37vh;
    .detail-section {
      padding-top: px-to-rem(20px);
      min-height: 200px;
    }
  }
}
