// MIXINS
@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

@mixin no-scrollbar {
  -ms-overflow-style: none; // IE 10+
  overflow: -moz-scrollbars-none; // Firefox
  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
}

@mixin reset-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin interactive() {
  cursor: pointer;
}

@mixin background-image($file-name) {
  background-image: url($path-image + $file-name);
}

.interactive {
  @include interactive();
}

$fontSizeMap: (
  null: $fs-base,
  xxxxxxl: $fs-xxxxxxl,
  xxxxxl: $fs-xxxxxl,
  xxxxl: $fs-xxxxl,
  xxxl: $fs-xxxl,
  xxl: $fs-xxl,
  xl: $fs-xl,
  lg: $fs-lg,
  sm: $fs-sm,
  xs: $fs-xs,
  xxs: $fs-xxs,
);

@mixin font-size($key: null) {
  @if map-has-key($fontSizeMap, $key) {
    $size: map-get($fontSizeMap, $key);
    font-size: px-to-rem($size);
  } @else {
    $size: $fs-base;
    font-size: px-to-rem($size);
  }
}

//FUNCTIONS

@mixin background-image($file-name) {
  background-image: url($path-image + $file-name);
}

@mixin vertical-align {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
@mixin horizontal-align {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
@mixin center($direction, $position: absolute) {
  @if $position == "relative" {
    position: relative;
  } @else {
    position: absolute;
  }

  @if $direction == "vertical" {
    top: 50%;
    transform: translateY(-50%);
  } @else if $direction == "horizontal" {
    left: 50%;
    transform: translateX(-50%);
  } @else if $direction == "both" {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@mixin mobile-drop-down-menu {
  display: block;
  width: 100%;
  padding-top: px-to-rem(20px);
  position: relative;

  .mobile-dropdown-trigger {
    display: block;
    width: 100%;
    background-color: $color-white;
    padding: px-to-rem(15px) px-to-rem(55px) px-to-rem(15px) px-to-rem(20px);
    font-weight: bold;
    color: $color-gray-4;
    font-size: $fs-lg;
    position: relative;
    margin: 0 auto;

    @include interactive();

    &:after {
      @include iconStyle();
      content: "\e905";
      display: inline-block;
      margin-left: 4px;
      position: absolute;
      top: 12px;
      right: 20px;
      font-size: $fs-xxl;
    }

    &.expanded {
      &:after {
        transform: rotate(180deg);
      }
    }
  }

  ul {
    max-height: 0;
    overflow: hidden;
    background-color: $color-white;
    position: absolute;
    top: 65px;
    left: 0;
    width: 100%;
    box-shadow: 0 3px 3px 0 rgba(95, 95, 95, 0.6);
    z-index: 1;
    transition: max-height 0.3s ease-in-out;
    &.active {
      max-height: 70vh;
      overflow: auto;
    }
    li {
      padding: 0;
      border: none;

      a {
        padding: px-to-rem(15px);
        border-bottom: solid 1px $color-gray-d;

        &.active {
          display: none;
        }

        &:hover {
          background-color: lighten($color-gray-9, 30%);
        }
      }
    }
  }
}
