.chapter-nav {
  $nav-button-size: 60px;
  $animation: 0.3s ease-in-out;
  @include vertical-align;
  height: px-to-rem($nav-button-size);
  width: px-to-rem($nav-button-size);
  font-size: px-to-rem($nav-button-size);
  color: $color-brand-red-d;
  .icon {
    position: relative;
    height: px-to-rem($nav-button-size);
    width: px-to-rem($nav-button-size);
    display: inline-block;
    cursor: pointer;
    &:before {
      display: inline-block;
      position: relative;
      top: -20%;
      background-color: $color-white;
      border: solid 1.2px $color-gray-d;
    }
    &:hover {
      .text-slide-out {
        max-width: 400px;
        z-index: 1;
        padding: 0 px-to-rem(20px);
        transition: padding $animation, max-width $animation;
      }
    }
  }
  &.next {
    right: 15%;
    .icon {
      &:before {
        transform: rotate(-90deg);
      }
    }
  }
  &.prev {
    left: 15%;
    .icon {
      &:before {
        transform: rotate(90deg);
      }
    }
  }
  .text-slide-out {
    font-size: px-to-rem(15px);
    font-weight: bold;
    height: px-to-rem($nav-button-size + 1.2px);
    background-color: $color-white;
    color: $color-gray-3;
    display: inline-block;
    position: absolute;
    white-space: nowrap;
    line-height: px-to-rem($nav-button-size);
    padding: 0;
    z-index: -1;
    top: -1.2px;
    overflow: hidden;
    max-width: 0;
    border: solid 1.2px $color-gray-d;
    transition: padding $animation, max-width $animation, border $animation;
    &.next-text {
      right: px-to-rem($nav-button-size - 4px);
    }
    &.prev-text {
      left: px-to-rem($nav-button-size - 8px);
    }
  }
  @include screen($bp-tablet) {
    &.next {
      right: 7.5%;
    }
    &.prev {
      left: 7.5%;
    }
  }
  @include screen($bp-phablet) {
    $nav-button-size: 40px;
    height: px-to-rem($nav-button-size);
    width: px-to-rem($nav-button-size);
    font-size: px-to-rem($nav-button-size);
    &.next {
      right: 2px;
    }
    &.prev {
      left: 0%;
    }
    .icon {
      height: px-to-rem($nav-button-size);
      width: px-to-rem($nav-button-size);
    }
    .text-slide-out {
      height: px-to-rem($nav-button-size);
      line-height: px-to-rem($nav-button-size);
      border: none;
      &.next-text {
        right: px-to-rem($nav-button-size);
      }
      &.prev-text {
        left: px-to-rem($nav-button-size - 6px);
      }
    }
  }
}
