.page-general {
  $navigation-width: 20%;
  $navigation-width-min: 200px;
  .in-page-navigation {
    display: inline-block;
    width: $navigation-width;
    min-width: $navigation-width-min;

    .mobile-dropdown-trigger {
      display: none;
    }
    ul {
      list-style: none;
      list-style-type: none;
      padding: 0;
      display: block;
      li {
        border-bottom: solid 1px $color-gray-d;
        padding: px-to-rem(15px) 0;

        &:first-child {
          padding-top: 3px;
        }
        &:last-child {
          border: none;
        }

        .sub-menu-link {
          font-weight: bold;
          display: block;
          color: $color-brand-red;
          font-size: $fs-lg;
          transition: color 0.3s linear;
          &.active {
            color: $color-gray-3;
          }
        }
        a {
          &:hover {
            color: $color-gray-4;
          }
        }
      }
    }
  }
  .general-content {
    display: inline-block;
    width: calc(100% - #{$navigation-width-min});
    padding-left: 60px;
    max-width: 100ch;
  }
  h1 {
    color: $color-gray-3;
    margin-bottom: px-to-rem(20px);
    font-size: px-to-rem($fs-h2);
  }
  @include screen($bp-phablet) {
    .in-page-navigation {
      @include mobile-drop-down-menu;

      .sub-menu-link {
        &.active {
          display: none;
        }
      }
    }
    .general-content {
      display: block;
      width: 100%;
      padding-left: 0;
      padding-top: px-to-rem(40px);
    }
    h1 {
      font-size: px-to-rem($fs-h3);
      margin-bottom: px-to-rem(30px);
    }
  }
}
