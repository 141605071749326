.rich-text {
  color: $color-gray-2;
  p {
    font-size: px-to-rem($fs-base);
    line-height: px-to-rem(27px);
  }
  img {
    max-width: 100%;
    height: auto;
  }
  .video-wrapper {
    float: none;
    clear: both;
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  @include screen($bp-tablet) {
    table {
      width: 100% !important;
    }
  }
}
