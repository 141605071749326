.site-credit {
  text-align: center;

  .title {
    margin-right: 0.5em;
    @include font-size();
  }
  .logo {
    width: 76px;
    > img {
      width: 76px;
    }
  }
  .sponsor-additionaltext {
    padding-top: $spacing;
  }
}
