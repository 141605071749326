﻿.glossary-term-trigger {
  color: $color-brand-red-d;
  border-bottom: 1px $color-brand-red-d dotted;
  cursor: pointer;
  white-space: nowrap;
}
.glossary-modal {
  display: none;
  min-width: 200px;
  max-width: 400px;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 3px 3px 0 $global-box-shadow-color;
  background-color: $color-gray-f;
  z-index: 100;
  .glossary-container {
    position: relative;
    width: 100%;
  }

  .glossary-close {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: px-to-rem(12px);
    cursor: pointer;
  }

  .term-text {
    font-size: $fs-glossary-term-header;
    font-weight: bold;
    padding: 15px 15px 0 15px;
  }
  .term-definition {
    font-size: $fs-glossary-definition;
    padding: 10px 15px 15px 15px;

    ul {
      padding-left: 40px;
      list-style-type: disc;
      list-style: disc;
      ul {
        list-style-type: circle;
        list-style: circle;
      }
    }
    ol {
      padding-left: 40px;
      list-style-type: decimal;
      list-style: decimal;
      ol {
        list-style-type: lower-alpha;
        list-style: lower-alpha;
      }
    }
  }

  @include screen($bp-mobile) {
    width: 90vw;
  }
}
body.show-modal {
  .glossary-modal {
    z-index: 1001;
  }
}
