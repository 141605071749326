html {
  -ms-overflow-style: scrollbar;
}
.site-wrapper {
  position: relative;
  min-height: 100%;
}
//remove IE defaults
select::-ms-expand {
  display: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $font-family-primary;
  font-weight: bold;
  line-height: 1.1;
}

h1,
.h1 {
  font-size: px-to-rem($fs-h1);
}
h2,
.h2 {
  font-size: px-to-rem($fs-h2);
}
h3,
.h3 {
  font-size: px-to-rem($fs-h3);
}

a {
  text-decoration: none;
  color: $color-brand-red-d;
  transition: color 0.2s ease-in-out;
  &:hover {
    color: $color-gray-4;
  }
}
