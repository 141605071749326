//  Brandon Mathis Media Queries
///////////////////////////////////////////////////////////////////////////////
//
//	http://codepen.io/imathis/pen/BDzcI
//
//  You should use the Breakpoint Aliases (below) when referencing media query
//  breakpoints with your custom CSS
//
//	Breakpoint Values
//	http://www.paintcodeapp.com/news/iphone-6-screens-demystified
///////////////////////////////////////////////////////////////////////////////

$bp-desktop-wide: 1450px;
$bp-desktop: 1200px;
$bp-tablet: 1024px;
$bp-phablet: 768px;
$bp-mobile: 630px;
$bp-mobile-portrait: 420px;

@mixin screen($device-width, $type: max) {
  @if $type == "min" {
    @media screen and (min-width: $device-width + 1) {
      @content;
    }
  } @else {
    @media screen and (max-width: $device-width) {
      @content;
    }
  }
}
@mixin screenhw($device-width, $device-height, $type: max) {
  @if $type == "min" {
    @media screen and (min-width: $device-width + 1) {
      @content;
    }
    @media screen and (min-height: $device-height + 1) {
      @content;
    }
  } @else {
    @media screen and (max-width: $device-width) {
      @content;
    }
    @media screen and (max-height: $device-height) {
      @content;
    }
  }
}
@mixin screenheight($device-height, $type: max) {
  @if $type == "min" {
    @media screen and (min-height: $device-height + 1) {
      @content;
    }
  } @else {
    @media screen and (max-height: $device-height) {
      @content;
    }
  }
}

@mixin media-height($device-height) {
  @media screen and (max-height: $device-height) {
    @content;
  }
}

@mixin media-orientation() {
  @media screen and (max-width: $bp-mobile) and (max-height: $bp-mobile-portrait) {
    @content;
  }
}
