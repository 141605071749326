.topic-help {
  @include detail-padding(40px);
  background-color: transparent;
  .input-container {
    max-width: 90%;
    width: 425px;
    background-color: transparent;
    border-bottom: solid 1.2px $color-gray-d;
    input {
      background-color: transparent;
      &:after {
        content: "X";
        font-weight: bold;
        font-size: px-to-rem($fs-xl);

        @include center("vertical", "absolute");
      }
    }
    .topic-help-icon {
      color: $color-brand-red-d;
      position: relative;
      top: 3px;
    }

    @include screen($bp-mobile) {
      width: 99%;
      max-width: 99%;

      input {
        width: 92%;
      }
    }
  }
}
