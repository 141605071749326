/*******************************************
  COLUMNS | grid structures
*******************************************/

//using bootstrap grid functionality

[class^="columns-"],
[class*=" columns-"] {
  @extend .reset-list;
  @include make-row();
}

.column {
  @include make-col-ready();
}

.columns-3 {
  .column {
    @include make-col(4);
    @include screen($bp-tablet) {
      @include make-col(6);
    }
    @include screen($bp-phablet) {
      @include make-col(12);
    }
  }
}
