﻿.translation-menu {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  color: $color-white;
  padding: px-to-rem(8px) px-to-rem(20px); // 20px is $header-padding
  &--home {
    padding-left: px-to-rem(10px);
    position: relative;
    top: auto;
    left: auto;
  }

  &__input-wrapper {
    position: relative;

    &:after {
      @include icon("keyboard_arrow_down");
      position: absolute;
      right: 0;
      top: px-to-rem(4px);
    }
  }

  &__input {
    @extend .interactive;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: inherit;
    font-weight: 700;
    background: transparent;
    border: none;
    padding-left: px-to-rem(8px);
    padding-right: px-to-rem(20px);
    position: relative;
    z-index: 1; // on top of keyboard_arrow_down icon
    &::-ms-expand {
      display: none;
    }

    option {
      color: $color-black;
    }
  }

  @include screenhw($bp-phablet, $bp-mobile-portrait) {
    z-index: 101; // .header z-index + 1
  }
}
