$topic-margin: 10px;
$scale: 1.3;
$animation: 0.3s ease-in-out;
$topicFontSize: 22px;
$topicDescriptionFontSize: 14px;
.topic-grid {
  @include detail-padding;
  position: relative;
  z-index: 10;
  .grid-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 30px;
    list-style: none;
    list-style-type: none;
    padding-left: 0;
    justify-content: center;
  }
  .topic-item {
    display: inline-block;
    width: calc(33.3333% - #{$topic-margin});
    margin-bottom: $topic-margin;
    text-align: center;
    background-color: $color-white;
    border: 1px solid $color-gray-d;
    overflow: visible;
    position: relative;
    opacity: 0;
    margin-right: px-to-rem(10px);

    &:hover {
      z-index: 10;
    }
  }

  .topic-container {
    position: relative;
    height: 100%;
    width: 100%;
    transition: transform $animation, background-color $animation;
    cursor: pointer;
    padding: px-to-rem(14px) px-to-rem(24px);
    min-height: px-to-rem(96px);
    display: flex;
    justify-content: center;
    align-content: center;
    &.active {
      background-color: $color-green;
      .topic-name {
        color: $color-white;
      }
    }
  }
  .topic-name {
    font-size: px-to-rem($topicFontSize);
    color: $color-green;
    line-height: 1.2;
    transition: color $animation;
    font-weight: bold;
    display: block;
  }
  .topic-description {
    font-size: px-to-rem(14px);
    color: $color-green;
    height: 0;
    opacity: 0;
    display: none;
    transition: height $animation, color $animation;
    padding: 0 px-to-rem(20px);
  }

  @include screen($bp-tablet) {
    .topic-item {
      width: calc(50% - #{$topic-margin});
    }
  }
  @include screen($bp-phablet) {
    .topic-item {
      width: calc(100%);
    }
  }
}
html.no-touch {
  .topic-grid {
    .topic-container {
      &.tile-hovered {
        &:not(.active) {
          &:hover {
            transform: scale($scale);
            background-color: $color-green;

            .topic-name {
              color: $color-white;
              font-size: px-to-rem($topicFontSize / $scale);
            }
            .topic-description {
              opacity: 1;
              height: auto;
              color: $color-white;
              margin-top: 4px;
              display: block;
              font-size: px-to-rem($topicDescriptionFontSize / $scale);
            }
          }
        }
      }
    }
  }
}

.button__download {
  .topic-name {
    color: $color-white !important;
  }

  .download_icon {
    padding-left: 0.5rem;
    height: px-to-rem($topicFontSize);
  }
}
