$header-padding: 20px;
$translation-menu-height: 40px;
$translation-menu-height-mobile: 31.78px;

.header {
  $header-horizontal-spacing: 20px;
  $header-logo-width: 50%;
  height: px-to-rem($header-height);
  width: 100%;
  position: fixed;
  background-color: $color-white;
  z-index: $z-index-header;
  padding: 0 px-to-rem($header-padding);
  top: 0;
  display: flex;
  transition: height 0.3s ease-in-out;
  box-shadow: 0 3px 3px 0 rgba(95, 95, 95, 0.6);

  &.minimized {
    $menu-button-size: $menu-button-size * $header-minimize-factor;
    height: px-to-rem($header-height * 0.56);
    @include menu-minimized($menu-button-size);

    .logo-default {
      height: $header-small-logo-height;

      img {
        height: 100%;
      }
    }
  }

  &.menu-active {
    position: relative;
  }

  .header-section-logo {
    width: $header-logo-width;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .header-section-sponsors {
    width: (100% - $header-logo-width);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 4;
    padding-right: 70px;
  }

  .header-section-escape {
    width: 76px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .logo-default {
    display: inline-flex;
    height: $header-logo-height;
    margin-left: $header-horizontal-spacing;
    transition: height 0.3s ease-in-out;

    img {
      transition: height 0.3s ease-in-out;
      height: 100%;
    }
  }

  .sponsors-text {
    font-size: px-to-rem(12px);
    font-weight: bold;
    color: $color-gray-4;
    display: inline-block;
  }

  .sponsor-image {
    display: inline-block;
    max-height: px-to-rem(42px);
    margin-left: $header-horizontal-spacing;

    &:last-child {
      max-width: 150px;
    }
  }

  &--with-translation-menu {
    top: px-to-rem($translation-menu-height);
    transition: height 0.3s ease-in-out, top 0.3s ease-in-out;

    &.minimized {
      top: 0;
    }

    &.menu-active {
      top: 0;
    }
  }

  @include screenhw($bp-phablet, $bp-mobile-portrait) {
    position: relative;
    display: block;
    padding: 0;

    .logo-default {
      height: $header-small-logo-height;
    }

    &.minimized {
      @include menu-minimized($menu-mobile-button-size);

      .logo-default {
        height: $header-small-logo-height;
      }

      .header-section-logo {
        height: $menu-mobile-button-size;
      }

      .menu-button {
        .menu-text {
          font-size: 7px;
        }
      }
    }

    .header-section-logo {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: px-to-rem($header-height * 0.8);
      padding: 0 px-to-rem($header-padding);
      background-color: $color-white;
      z-index: 10;
      transition: height 0.3s ease-in-out;
    }

    .header-section-sponsors {
      position: relative;
      top: px-to-rem($header-height * 0.8);
      width: 100%;
      padding: 10px px-to-rem($header-padding);
      background-color: $color-white;
      flex-wrap: wrap;
      text-align: center;
      justify-content: center;
      z-index: 8;
      height: auto;
    }

    .sponsors-text {
      display: block;
      width: 100%;
      font-size: px-to-rem(16px);
      margin-bottom: 4px;
    }

    .sponsor-image {
      margin-left: 0;
      padding-right: px-to-rem(20px);
      margin-bottom: 4px;
      max-width: 33.333%;
    }

    &--with-translation-menu {
      margin-top: 0;

      &.minimized {
        z-index: 102; // cover .translation-menu

        .header-section-logo {
          top: 0;
        }
      }

      .header-section-logo {
        top: $translation-menu-height-mobile;
        transition: height 0.3s ease-in-out, top 0.3s ease-in-out;
      }
    }
  }
}
