.print-header {
  display: none;
}
@media print {
  .print-header {
    display: block;
    position: relative;
    z-index: 1001;
    .logo-default {
      display: inline-flex;
      height: $menu-button-size;
      margin-left: 20px;
      transition: height 0.3s ease-in-out;
      img {
        transition: height 0.3s ease-in-out;
        height: 100%;
      }
    }
  }
  .global-menu,
  .header,
  .footer,
  .word-cloud,
  .topic-grid,
  .topic-help,
  .input-container,
  .chapter-nav,
  .in-page-navigation {
    display: none !important;
  }
  .answer-content {
    height: auto !important;
  }
  .topic-detail-container,
  .chapter-intro-header,
  .page-content,
  .chapter-intro,
  .general-content {
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .hero-section {
    padding-top: 0 !important;
  }
  .page-content,
  .detail-section {
    margin-top: 20px !important;
  }
}
