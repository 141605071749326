/*!
 * Bootstrap v4.0.0-alpha.6 (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
// @import "variables";
@import "../vendor/bootstrap/mixins";

// Core CSS
@import "../vendor/bootstrap/reboot";
@import "../vendor/bootstrap/grid";
