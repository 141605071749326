.page {
  min-height: 66vh;
  min-width: 90vw;
  .background-image {
    position: fixed;
    background-size: cover;
    //background-attachment: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: $color-white;
  }

  @include screen($bp-tablet) {
    min-height: 50vh;
  }
  @include screen($bp-phablet) {
    min-height: 37vh;
  }
}
