.topic-detail-container {
  @include topic-detail-padding;

  max-width: 100rem;
  margin: 0 auto;
  padding-bottom: px-to-rem(50px);
  padding-top: px-to-rem(30px);

  @include screen($bp-tablet) {
    padding-bottom: px-to-rem(50px);
    padding-top: px-to-rem(30px);
  }
  .topic-detail-title {
    text-align: center;
    margin-bottom: px-to-rem(35px);
  }

  .questions-container {
    border-bottom: solid 1.2px $color-gray-d;
  }
  .answer-content {
    height: 0;
    transition: height 0.5s linear;
    overflow: hidden;
  }
  .question {
    transition: height 0.5s linear;
    &.expanded {
      .answer-content {
        height: auto;
      }

      .question-title {
        &:after {
          content: "-";
        }
      }
    }
  }
  .question-expander {
    border-top: solid 1.2px $color-gray-d;
    padding: px-to-rem(30px) 0;
    cursor: pointer;
    position: relative;
    width: 100%;
    outline: none;
    text-align: left;
  }

  .question-title {
    font-size: px-to-rem($fs-xl);
    font-weight: bold;
    width: 90%;

    &:after {
      @include center("vertical", "absoulte");

      content: "+";
      right: 25px;
      font-size: px-to-rem($fs-expandCollapseIcon);
      font-weight: bold;
    }
  }

  .sub-topics-container {
    margin-top: 5em;
  }

  .sub-topic-item {
    margin-top: 5em;
    &:first-child {
      margin-top: 0;
    }
  }

  .sub-topics-tabs-container {
    margin-bottom: 2em;

    .mobile-dropdown-trigger {
      display: none;
    }
  }

  .sub-topics-tabs-list {
    list-style: none;
    list-style-type: none;
    text-align: center;
    padding: 0;
    margin: 0;

    .sub-topic-tab {
      padding: 1em;
      margin: 0 1em 1em 0;
      border: 1px solid $color-green;
      background-color: $color-white;
      transition: background-color 0.3s ease-in-out;
      display: inline-block;
      font-weight: bold;
      font-size: px-to-rem($fs-sm);
      cursor: pointer;
      button {
        outline: none;
        color: $color-green;
        font-weight: bold;
        display: block;
        width: 100%;
        height: 100%;
      }
      &:hover,
      &.active-tab {
        background-color: $color-green;
        button {
          color: $color-white;
        }
      }
    }
  }

  &.word-cloud-results {
    .topic-detail {
      padding-top: px-to-rem(50px);
    }
    .no-wordcloud-results {
      font-size: px-to-rem(20px);
      font-weight: bold;
      text-align: center;
    }
  }
  @include screen($bp-phablet) {
    .sub-topics-tabs-container {
      @include mobile-drop-down-menu;

      .sub-topics-tabs-list {
        .sub-topic-tab {
          margin-left: 1rem;
          margin-right: 1rem;
          display: block;
        }
      }
    }
    .topic-detail-title {
      h3 {
        font-size: px-to-rem($fs-xxxl);
      }
    }
  }
  @include screen($bp-mobile) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    .question-title {
      width: 85%;

      &:after {
        right: 10px;
      }
    }
  }
}
