.footer {
  $footer-padding: 30px;
  position: relative;
  margin-top: px-to-rem(125px);
  min-height: px-to-rem($footer-height);
  background-color: $color-gray-3;
  color: $color-white;
  font-size: px-to-rem(14px);
  font-weight: bold;
  line-height: px-to-rem(18px);
  align-items: center;
  padding: px-to-rem(30px);

  &.home-footer {
    margin-top: 0;
  }

  .footer-top {
    display: flex;
    justify-content: space-between;
  }
  .footer-bottom {
    margin-left: px-to-rem(40px);
    color: $color-white;
    font-weight: normal;
    margin-top: px-to-rem(15px);
    font-size: px-to-rem($fs-sm);

    &.rich-text {
      p {
        font-size: $fs-sm;
      }
    }
  }
  .footer-left,
  .footer-right {
    width: calc(50% - 20px);
    height: ($footer-height - $footer-padding * 2);
  }
  .footer-link,
  a {
    color: $color-white;
    line-height: ($footer-height - $footer-padding * 2);
    transition: opacity 0.3s linear;
  }
  a {
    &:hover {
      opacity: 0.75;
    }
  }
  span.footer-link {
    font-weight: normal;
  }
  ul.footer-links {
    list-style: none;
    height: 100%;
    margin-bottom: 0;
    li {
      display: inline-block;
      height: 100%;
      line-height: 100%;
      + li {
        &:before {
          content: "|";
          margin: 0 8px 0 4px;
        }
      }
    }
  }
  .footer-right {
    text-align: right;
    p {
      margin-bottom: 0;
      line-height: ($footer-height - $footer-padding * 2);
    }
    img {
      margin-left: 10px;
    }
  }
  @include screen($bp-tablet) {
    height: auto;
    .footer-top {
      display: block;
    }
    .footer-bottom {
      margin-left: 0;
    }
    .footer-left,
    .footer-right {
      width: 100%;
      height: auto;
      text-align: center;
    }
    ul.footer-links {
      padding-left: 0;
      margin-left: 0;
    }
    a,
    p {
      line-height: 1.5;
    }
  }
}
