@mixin page-padding($vertical-padding: 0px) {
  padding: px-to-rem($vertical-padding) px-to-rem($page-side-margins);
  @include screen($bp-tablet) {
    padding: px-to-rem($vertical-padding) px-to-rem($page-side-margins-tablet);
  }
  @include screen($bp-phablet) {
    padding: px-to-rem($vertical-padding) px-to-rem($page-side-margins-mobile);
  }
}
@mixin detail-padding($vertical-padding: 0px) {
  padding: px-to-rem($vertical-padding) px-to-rem($detail-section-padding);
  @include screen($bp-tablet) {
    padding: px-to-rem($vertical-padding)
      px-to-rem($detail-section-padding-tablet);
  }
  @include screen($bp-phablet) {
    padding: px-to-rem($vertical-padding)
      px-to-rem($detail-section-padding-mobile);
  }
}
@mixin topic-detail-padding($vertical-padding: 0px) {
  padding: px-to-rem($vertical-padding) px-to-rem($topic-detail-section-padding);
  @include screen($bp-tablet) {
    padding: px-to-rem($vertical-padding)
      px-to-rem($topic-detail-section-padding-tablet);
  }
  @include screen($bp-phablet) {
    padding: px-to-rem($vertical-padding)
      px-to-rem($topic-detail-section-padding-mobile);
  }
}
.main {
  // width: 100vw;
}
.site-wrapper {
  @include clearfix();
}
.page-content {
  margin-top: px-to-rem($header-height);
  position: relative;
  @include page-padding;
}
