/*******************************************
            FONT SIZES
********************************************/
// arguments in pixels i.e. (12px, 11px)

$set-font-base-val: 16; //save for future calcs
$set-font-size: $set-font-base-val * 1px; //set html base font

$font-size: $set-font-size;

// arguments in pixels i.e. px-to-rem(12px)

@function px-to-rem($fpx, $base: $set-font-size) {
  @return ($fpx / $base) * 1rem;
}

/*** to bootstrap ***/

//only use pixels, they will be converted later to "rem"
$fs-base: $font-size;

$fs-xxxxxxl: 64px;
$fs-xxxxxl: 48px;
$fs-xxxxl: 36px;
$fs-xxxl: 33px;
$fs-xxl: 28px;
$fs-xl: 24px;

$fs-lg: 18px;
$fs-sm: 14px;
$fs-xs: 12px;
$fs-xxs: 10px;

$fs-h1: $fs-xxxxxxl;
$fs-h2: $fs-xxxxxl;
$fs-h3: $fs-xxxxl;
$fs-h4: $fs-xxl;
$fs-h5: $fs-xl;
$fs-h6: $fs-lg;

$fs-expandCollapseIcon: 40px;
$fs-typeahead-results: 16px;
$fs-glossary-term-header: 20px;
$fs-glossary-definition: 16px;

/*
// now we can use the following variables
$font-size-base
$font-size-lg
$font-size-sm
$font-size-xs

$font-size-h1
$font-size-h2
$font-size-h3
$font-size-h4
$font-size-h5
$font-size-h6
*/

html {
  font-size: $font-size;
  @include screen($bp-tablet) {
    font-size: $font-size * 0.9;
  }
  @include screen($bp-mobile) {
    font-size: $font-size * 0.8;
  }
}
