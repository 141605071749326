.detail-section {
  margin-top: 60px;
  width: 100%;
  background-color: $color-gray-f;
  padding: px-to-rem(20px) 0 0;
  display: none;
  opacity: 0;

  .tagline {
    text-align: center;
    margin-bottom: px-to-rem(40px);
    margin-top: px-to-rem(20px);
    @include detail-padding;
  }

  &.active-section,
  &.only-section {
    display: block;

    &.gc-detail-section {
      display: flex;
      @include detail-padding(80px);
      @include screen($bp-phablet) {
        display: block;
        padding-top: 0;
      }
    }
  }
  &.only-section {
    opacity: 1;
  }
}
