/*******************************************
            COLORS | base
********************************************/
$color-white: #fff;
$color-black: #000;

$color-gray-2: #262626;
$color-gray-3: #333335;
$color-gray-4: #474a48;
$color-gray-9: #9b9b9b;
$color-gray-d: #dedede;
$color-gray-f: #f7f7f7;

$color-brand-red: #b0182f;
$color-brand-red-d: #d93253;
$color-brand-red-lt: #ee3135;
$color-brand-yellow: #f1c13c;

$color-brand-light: $color-white;
$color-brand-dark: $color-black;
$color-green-a: #a2c845;
$color-blue-purple: #395dd8;
$color-green: #21caa4;
$color-brand-primary: $color-brand-red-lt;
$color-brand-secondary: $color-gray-3;

/*******************************************
            COLORS | text and interface
********************************************/

$font-color: $color-brand-dark;

$color-link: $color-brand-red-lt;
$color-link-hover: $color-brand-red;

$color-input-border: $color-gray-d;
$color-input-back: $color-gray-f;

$font-color-header: $color-gray-3;
$global-box-shadow-color: rgba(95, 95, 95, 0.6);

/*******************************************
            COLORS | classes
********************************************/
$body-color: $font-color !default;
$link-color: $color-link !default;
$link-decoration: none !default;
$link-hover-color: $color-link-hover !default;
$link-hover-decoration: underline !default;
