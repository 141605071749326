@charset "UTF-8";
/*******************************************
  SETUP | reusable variables and config
********************************************/
/*******************************************
            SETTINGS
********************************************/
/*******************************************
            PATHS
********************************************/
/*******************************************
            FONT STARTUP
********************************************/
/*******************************************
            SPACING
********************************************/
/*******************************************
            Z-INDEX
********************************************/
/*******************************************
            LAYOUT
********************************************/
/*******************************************
            MENU
********************************************/
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

html,
button,
input,
select,
textarea {
  font-family: sans-serif;
}

body {
  margin: 0;
}

a {
  background: transparent;
}
a:focus {
  outline: thin dotted;
}
a:hover, a:active {
  outline: 0;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

h2 {
  font-size: 1.5em;
  margin: 0.83em 0;
}

h3 {
  font-size: 1.17em;
  margin: 1em 0;
}

h4 {
  font-size: 1em;
  margin: 1.33em 0;
}

h5 {
  font-size: 0.83em;
  margin: 1.67em 0;
}

h6 {
  font-size: 0.75em;
  margin: 2.33em 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

mark {
  background: #ff0;
  color: #000;
}

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

q {
  quotes: "“" "”" "‘" "’";
}

q:before, q:after {
  content: "";
  content: none;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}

button,
input {
  line-height: normal;
}

button,
select {
  text-transform: none;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

/*******************************************
  BASE | site specific defaults
********************************************/
/*******************************************
            COLORS | base
********************************************/
/*******************************************
            COLORS | text and interface
********************************************/
/*******************************************
            COLORS | classes
********************************************/
/*******************************************
            FONT SIZES
********************************************/
/*** to bootstrap ***/
/*
// now we can use the following variables
$font-size-base
$font-size-lg
$font-size-sm
$font-size-xs

$font-size-h1
$font-size-h2
$font-size-h3
$font-size-h4
$font-size-h5
$font-size-h6
*/
html {
  font-size: 16px;
}
@media screen and (max-width: 1024px) {
  html {
    font-size: 14.4px;
  }
}
@media screen and (max-width: 630px) {
  html {
    font-size: 12.8px;
  }
}

.interactive, .translation-menu__input, .selectbox > select, .selectbox {
  cursor: pointer;
}

/*******************************************
      HELPER UTILITIES
********************************************/
.background-full, .locations-section-inner, .decor-background, html:not([data-agent*=Trident]) .frost-overlay::before {
  background-size: cover;
  background-position: center;
  background-color: transparent;
  background-repeat: no-repeat;
}

.reset-list, [class^=columns-],
[class*=" columns-"] {
  list-style: none;
  margin: 0;
  padding: 0;
}

.reset-link {
  text-decoration: none;
}
.reset-link:hover, .reset-link:visited, .reset-link:active, .reset-link:focus {
  text-decoration: none;
}

.link-block {
  display: block;
}

.no-scrollbar {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: "icomoon";
  src: url("/assets/public/fonts/icomoon/fonts/icomoon.eot?dzt4yx");
  src: url("/assets/public/fonts/icomoon/fonts/icomoon.eot?dzt4yx#iefix") format("embedded-opentype"), url("/assets/public/fonts/icomoon/fonts/icomoon.ttf?dzt4yx") format("truetype"), url("/assets/public/fonts/icomoon/fonts/icomoon.woff?dzt4yx") format("woff"), url("/assets/public/fonts/icomoon/fonts/icomoon.svg?dzt4yx#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-]:before, [class^=icon-]:after,
[class*=" icon-"]:before,
[class*=" icon-"]:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-icon {
  vertical-align: middle;
  position: relative;
  top: -1px;
  margin-left: 0.75em;
}

.global-menu .menu-handbook-link a:after, .icon-arrow-right:before {
  content: "\e900";
}

.search-top-container .back-to-page:before, .icon-arrow-left:before {
  content: "\e901";
}

.selectbox:before, .icon-arrow-down:before {
  content: "\e902";
}

.icon-arrow-up:before {
  content: "\e903";
}

.global-menu .primary-menu ul li.location-item.active-page .active-menu-arrow:before, .chapter-intro .drop-down-trigger:after, .translation-menu__input-wrapper:after, .icon-keyboard_arrow_down:before {
  content: "\e905";
}

.icon-search:before {
  content: "\e906";
}

.icon-menu:before {
  content: "\e904";
}

.icon-list:before {
  content: "\e904";
}

.icon-options:before {
  content: "\e904";
}

.icon-lines:before {
  content: "\e904";
}

.icon-hamburger:before {
  content: "\e904";
}

.icon-home:before {
  content: "\e907";
}

.icon-sort-amount-desc:before {
  content: "\ea4d";
}

.icon-cross:before {
  content: "\ea0f";
}

html {
  -ms-overflow-style: scrollbar;
}

.site-wrapper {
  position: relative;
  min-height: 100%;
}

select::-ms-expand {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: Muli, sans-serif;
  font-weight: bold;
  line-height: 1.1;
}

h1,
.h1 {
  font-size: 4rem;
}

h2,
.h2 {
  font-size: 3rem;
}

h3,
.h3 {
  font-size: 2.25rem;
}

a {
  text-decoration: none;
  color: #d93253;
  transition: color 0.2s ease-in-out;
}
a:hover {
  color: #474a48;
}

.site-wrapper::after {
  display: block;
  content: "";
  clear: both;
}

.page-content {
  margin-top: 6.25rem;
  position: relative;
  padding: 0rem 5rem;
}
@media screen and (max-width: 1024px) {
  .page-content {
    padding: 0rem 3.75rem;
  }
}
@media screen and (max-width: 768px) {
  .page-content {
    padding: 0rem 1.875rem;
  }
}

.input-container {
  background-color: #fff;
  padding: 0.625rem;
  margin: 0 auto;
  text-align: left;
}
.input-container button {
  background-color: transparent;
  outline: none;
  border: none;
  height: 25px;
  width: 25px;
}
.input-container input[type=text] {
  outline: none;
  border: none;
  color: #000;
  font-size: 0.75rem;
  font-weight: bold;
  width: calc(100% - 25px - 2 *10px);
}
@media screen and (max-width: 1024px) {
  .input-container input[type=text] {
    font-size: 16px;
  }
}

button {
  background-color: transparent;
  outline: none;
  border: none;
}

.btn-primary {
  font-size: 0.75rem;
  font-weight: bold;
  padding: 1.25rem 1.125rem;
  transition: color 0.3s ease-in-out;
  position: relative;
  z-index: 2;
  border: solid 1.2px #21caa4;
}

html.no-touch .btn-primary:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  background-color: #21caa4;
  color: #474a48;
  transition: height 0.3s ease-in-out;
  z-index: -1;
}
html.no-touch .btn-primary:hover {
  color: #fff;
}
html.no-touch .btn-primary:hover:before {
  height: 100%;
}

/*******************************************
  VENDOR | 3rd party libraries
********************************************/
/*!
 * Bootstrap v4.0.0-alpha.6 (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@-ms-viewport {
  width: device-width;
}
html {
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: Muli, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  cursor: help;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
}

[role=button] {
  cursor: pointer;
}

a,
area,
button,
[role=button],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
  background-color: transparent;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #636c72;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: left;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
textarea {
  line-height: inherit;
}

input[type=radio]:disabled,
input[type=checkbox]:disabled {
  cursor: not-allowed;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
}

input[type=search] {
  -webkit-appearance: none;
}

output {
  display: inline-block;
}

[hidden] {
  display: none !important;
}

.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 6.5px;
  padding-left: 6.5px;
}
@media (min-width: 576px) {
  .container {
    padding-right: 6.5px;
    padding-left: 6.5px;
  }
}
@media (min-width: 768px) {
  .container {
    padding-right: 6.5px;
    padding-left: 6.5px;
  }
}
@media (min-width: 992px) {
  .container {
    padding-right: 6.5px;
    padding-left: 6.5px;
  }
}
@media (min-width: 1200px) {
  .container {
    padding-right: 6.5px;
    padding-left: 6.5px;
  }
}
@media (min-width: 576px) {
  .container {
    width: 540px;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .container {
    width: 720px;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .container {
    width: 960px;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1140px;
    max-width: 100%;
  }
}

.container-fluid {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 6.5px;
  padding-left: 6.5px;
}
@media (min-width: 576px) {
  .container-fluid {
    padding-right: 6.5px;
    padding-left: 6.5px;
  }
}
@media (min-width: 768px) {
  .container-fluid {
    padding-right: 6.5px;
    padding-left: 6.5px;
  }
}
@media (min-width: 992px) {
  .container-fluid {
    padding-right: 6.5px;
    padding-left: 6.5px;
  }
}
@media (min-width: 1200px) {
  .container-fluid {
    padding-right: 6.5px;
    padding-left: 6.5px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -6.5px;
  margin-left: -6.5px;
}
@media (min-width: 576px) {
  .row {
    margin-right: -6.5px;
    margin-left: -6.5px;
  }
}
@media (min-width: 768px) {
  .row {
    margin-right: -6.5px;
    margin-left: -6.5px;
  }
}
@media (min-width: 992px) {
  .row {
    margin-right: -6.5px;
    margin-left: -6.5px;
  }
}
@media (min-width: 1200px) {
  .row {
    margin-right: -6.5px;
    margin-left: -6.5px;
  }
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 6.5px;
  padding-left: 6.5px;
}
@media (min-width: 576px) {
  .col-xl, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
    padding-right: 6.5px;
    padding-left: 6.5px;
  }
}
@media (min-width: 768px) {
  .col-xl, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
    padding-right: 6.5px;
    padding-left: 6.5px;
  }
}
@media (min-width: 992px) {
  .col-xl, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
    padding-right: 6.5px;
    padding-left: 6.5px;
  }
}
@media (min-width: 1200px) {
  .col-xl, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
    padding-right: 6.5px;
    padding-left: 6.5px;
  }
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.pull-0 {
  right: auto;
}

.pull-1 {
  right: 8.3333333333%;
}

.pull-2 {
  right: 16.6666666667%;
}

.pull-3 {
  right: 25%;
}

.pull-4 {
  right: 33.3333333333%;
}

.pull-5 {
  right: 41.6666666667%;
}

.pull-6 {
  right: 50%;
}

.pull-7 {
  right: 58.3333333333%;
}

.pull-8 {
  right: 66.6666666667%;
}

.pull-9 {
  right: 75%;
}

.pull-10 {
  right: 83.3333333333%;
}

.pull-11 {
  right: 91.6666666667%;
}

.pull-12 {
  right: 100%;
}

.push-0 {
  left: auto;
}

.push-1 {
  left: 8.3333333333%;
}

.push-2 {
  left: 16.6666666667%;
}

.push-3 {
  left: 25%;
}

.push-4 {
  left: 33.3333333333%;
}

.push-5 {
  left: 41.6666666667%;
}

.push-6 {
  left: 50%;
}

.push-7 {
  left: 58.3333333333%;
}

.push-8 {
  left: 66.6666666667%;
}

.push-9 {
  left: 75%;
}

.push-10 {
  left: 83.3333333333%;
}

.push-11 {
  left: 91.6666666667%;
}

.push-12 {
  left: 100%;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pull-sm-0 {
    right: auto;
  }

  .pull-sm-1 {
    right: 8.3333333333%;
  }

  .pull-sm-2 {
    right: 16.6666666667%;
  }

  .pull-sm-3 {
    right: 25%;
  }

  .pull-sm-4 {
    right: 33.3333333333%;
  }

  .pull-sm-5 {
    right: 41.6666666667%;
  }

  .pull-sm-6 {
    right: 50%;
  }

  .pull-sm-7 {
    right: 58.3333333333%;
  }

  .pull-sm-8 {
    right: 66.6666666667%;
  }

  .pull-sm-9 {
    right: 75%;
  }

  .pull-sm-10 {
    right: 83.3333333333%;
  }

  .pull-sm-11 {
    right: 91.6666666667%;
  }

  .pull-sm-12 {
    right: 100%;
  }

  .push-sm-0 {
    left: auto;
  }

  .push-sm-1 {
    left: 8.3333333333%;
  }

  .push-sm-2 {
    left: 16.6666666667%;
  }

  .push-sm-3 {
    left: 25%;
  }

  .push-sm-4 {
    left: 33.3333333333%;
  }

  .push-sm-5 {
    left: 41.6666666667%;
  }

  .push-sm-6 {
    left: 50%;
  }

  .push-sm-7 {
    left: 58.3333333333%;
  }

  .push-sm-8 {
    left: 66.6666666667%;
  }

  .push-sm-9 {
    left: 75%;
  }

  .push-sm-10 {
    left: 83.3333333333%;
  }

  .push-sm-11 {
    left: 91.6666666667%;
  }

  .push-sm-12 {
    left: 100%;
  }

  .offset-sm-0 {
    margin-left: 0%;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pull-md-0 {
    right: auto;
  }

  .pull-md-1 {
    right: 8.3333333333%;
  }

  .pull-md-2 {
    right: 16.6666666667%;
  }

  .pull-md-3 {
    right: 25%;
  }

  .pull-md-4 {
    right: 33.3333333333%;
  }

  .pull-md-5 {
    right: 41.6666666667%;
  }

  .pull-md-6 {
    right: 50%;
  }

  .pull-md-7 {
    right: 58.3333333333%;
  }

  .pull-md-8 {
    right: 66.6666666667%;
  }

  .pull-md-9 {
    right: 75%;
  }

  .pull-md-10 {
    right: 83.3333333333%;
  }

  .pull-md-11 {
    right: 91.6666666667%;
  }

  .pull-md-12 {
    right: 100%;
  }

  .push-md-0 {
    left: auto;
  }

  .push-md-1 {
    left: 8.3333333333%;
  }

  .push-md-2 {
    left: 16.6666666667%;
  }

  .push-md-3 {
    left: 25%;
  }

  .push-md-4 {
    left: 33.3333333333%;
  }

  .push-md-5 {
    left: 41.6666666667%;
  }

  .push-md-6 {
    left: 50%;
  }

  .push-md-7 {
    left: 58.3333333333%;
  }

  .push-md-8 {
    left: 66.6666666667%;
  }

  .push-md-9 {
    left: 75%;
  }

  .push-md-10 {
    left: 83.3333333333%;
  }

  .push-md-11 {
    left: 91.6666666667%;
  }

  .push-md-12 {
    left: 100%;
  }

  .offset-md-0 {
    margin-left: 0%;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pull-lg-0 {
    right: auto;
  }

  .pull-lg-1 {
    right: 8.3333333333%;
  }

  .pull-lg-2 {
    right: 16.6666666667%;
  }

  .pull-lg-3 {
    right: 25%;
  }

  .pull-lg-4 {
    right: 33.3333333333%;
  }

  .pull-lg-5 {
    right: 41.6666666667%;
  }

  .pull-lg-6 {
    right: 50%;
  }

  .pull-lg-7 {
    right: 58.3333333333%;
  }

  .pull-lg-8 {
    right: 66.6666666667%;
  }

  .pull-lg-9 {
    right: 75%;
  }

  .pull-lg-10 {
    right: 83.3333333333%;
  }

  .pull-lg-11 {
    right: 91.6666666667%;
  }

  .pull-lg-12 {
    right: 100%;
  }

  .push-lg-0 {
    left: auto;
  }

  .push-lg-1 {
    left: 8.3333333333%;
  }

  .push-lg-2 {
    left: 16.6666666667%;
  }

  .push-lg-3 {
    left: 25%;
  }

  .push-lg-4 {
    left: 33.3333333333%;
  }

  .push-lg-5 {
    left: 41.6666666667%;
  }

  .push-lg-6 {
    left: 50%;
  }

  .push-lg-7 {
    left: 58.3333333333%;
  }

  .push-lg-8 {
    left: 66.6666666667%;
  }

  .push-lg-9 {
    left: 75%;
  }

  .push-lg-10 {
    left: 83.3333333333%;
  }

  .push-lg-11 {
    left: 91.6666666667%;
  }

  .push-lg-12 {
    left: 100%;
  }

  .offset-lg-0 {
    margin-left: 0%;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pull-xl-0 {
    right: auto;
  }

  .pull-xl-1 {
    right: 8.3333333333%;
  }

  .pull-xl-2 {
    right: 16.6666666667%;
  }

  .pull-xl-3 {
    right: 25%;
  }

  .pull-xl-4 {
    right: 33.3333333333%;
  }

  .pull-xl-5 {
    right: 41.6666666667%;
  }

  .pull-xl-6 {
    right: 50%;
  }

  .pull-xl-7 {
    right: 58.3333333333%;
  }

  .pull-xl-8 {
    right: 66.6666666667%;
  }

  .pull-xl-9 {
    right: 75%;
  }

  .pull-xl-10 {
    right: 83.3333333333%;
  }

  .pull-xl-11 {
    right: 91.6666666667%;
  }

  .pull-xl-12 {
    right: 100%;
  }

  .push-xl-0 {
    left: auto;
  }

  .push-xl-1 {
    left: 8.3333333333%;
  }

  .push-xl-2 {
    left: 16.6666666667%;
  }

  .push-xl-3 {
    left: 25%;
  }

  .push-xl-4 {
    left: 33.3333333333%;
  }

  .push-xl-5 {
    left: 41.6666666667%;
  }

  .push-xl-6 {
    left: 50%;
  }

  .push-xl-7 {
    left: 58.3333333333%;
  }

  .push-xl-8 {
    left: 66.6666666667%;
  }

  .push-xl-9 {
    left: 75%;
  }

  .push-xl-10 {
    left: 83.3333333333%;
  }

  .push-xl-11 {
    left: 91.6666666667%;
  }

  .push-xl-12 {
    left: 100%;
  }

  .offset-xl-0 {
    margin-left: 0%;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
/*******************************************
  COMPONENTS | reusable component/modules
********************************************/
.site-logo-home {
  display: inline-block;
  width: 200px;
}
.site-logo-home .no-img {
  background-color: #333335;
  color: #fff;
  padding: 10px 1em;
  transition: background-color 0.3s linear;
}
.site-logo-home .no-img:hover {
  background-color: #1a1a1b;
  color: #fff;
  text-decoration: none;
}
.site-logo-home img {
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .site-logo-home {
    padding-left: 30px;
  }
}
@media screen and (max-width: 768px) {
  .site-logo-home {
    padding-left: 0;
  }
}

.menu-trigger {
  display: inline-block;
  padding: 0 10px 0;
  text-align: center;
  max-height: 100%;
}
@media (max-width: 991px) {
  .menu-trigger {
    color: #fff;
  }
  .menu-trigger .icon {
    display: block;
    font-size: 1.75rem;
  }
  .menu-trigger .label {
    display: block;
    position: relative;
    top: -1.2em;
    font-size: 0.625rem;
    text-transform: uppercase;
  }
}

/* IE10+ detection */
html[data-agent*=Trident] .frost-overlay {
  opacity: 0.75;
}

html:not([data-agent*=Trident]) .frost-overlay::before {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  content: " ";
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius="3");
  -webkit-filter: url(#blur-filter);
  filter: url(#blur-filter);
  -webkit-filter: blur(50px);
  filter: blur(50px);
  backface-visibility: hidden;
  perspective: 1000;
  transform: translate3d(0, 0, 0);
  transform: translateZ(0);
}

.locations-section {
  position: relative;
  padding-bottom: 40px;
  height: 100vh;
  overflow-y: auto;
}
@media (max-width: 1199px) {
  .locations-section {
    height: auto;
  }
}
@media (max-width: 991px) {
  .locations-section {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 991px) {
  .decor-background {
    background-attachment: fixed;
    background-position: 0 0;
  }
}

.frost-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0.98;
  background-color: white;
}

.locations-section-inner {
  position: relative;
  overflow: hidden;
  padding: 40px;
  margin: 0 80px 40px 80px;
}
@media screen and (max-width: 630px) {
  .locations-section-inner {
    margin: 0;
  }
}

.locations {
  position: relative;
  z-index: 2;
  margin-top: 80px;
}
.locations > .locations-title {
  margin-bottom: 2em;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.3em;
  color: #474a48;
}
.locations:first-child {
  margin-top: 0;
}
@media (max-width: 991px) {
  .locations > .locations-title {
    text-align: center;
    color: #fff;
  }
}

.locations .column {
  margin-bottom: 10px;
}

.location {
  box-sizing: border-box;
  background-color: #fff;
  height: 200px;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
}
.location .location-block {
  flex: 1 1 50%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}
.location .location-title {
  color: #333335;
  font-size: 1.75rem;
  transition: color 0.3s linear;
}
@media screen and (max-width: 1200px) and (min-width: 1024px) {
  .location .location-title {
    font-size: 1.5rem;
    padding: 0 2px;
  }
}
.location .tile-decor {
  position: relative;
  overflow: hidden;
}
.location .tile-decor > * {
  position: relative;
  z-index: 1;
}
.location .tile-decor:before {
  content: " ";
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 5px);
  background-color: #fff;
  opacity: 1;
  transition: height 0.3s ease-in;
}
.location .tile-border-decor {
  position: relative;
}
.location .tile-border-decor:before {
  display: block;
  position: absolute;
  content: " ";
  width: 80%;
  top: 0;
  left: 50%;
  margin-left: -40%;
  border-top: 1px solid #dedede;
  transition: all 0.3s ease-in;
}
.location .tile-border-decor .wrapper {
  width: 100%;
  height: 100px;
}
.location .tile-image-block {
  margin: 0 30px;
  padding: 5px 0;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 100px;
}
.location .tile-image-block .image-wrapper {
  display: inline-block;
  flex-grow: 1;
  flex-basis: 50%;
  align-items: center;
}
.location .tile-image-block .image-wrapper:first-child {
  margin-right: 5px;
}
.location .tile-image-block .image-wrapper:nth-child(3) {
  margin-left: 5px;
}
.location .tile-image-block img {
  display: block;
  max-height: 60px;
  max-width: 100%;
  margin: 0 auto;
}
.location:hover .location-title {
  color: #fff;
}
.location:hover .tile-decor:before {
  height: 0px;
}
.location:hover .tile-border-decor:before {
  width: 0%;
  top: 10%;
  margin-left: 0%;
}

.columns-3 .column {
  width: 33.3333%;
}

.selectbox {
  position: relative;
  z-index: 0;
  overflow: hidden;
  background-color: #f7f7f7;
  border: 1px solid #dedede;
  border-radius: 0;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
}
.selectbox:focus {
  background-color: #f7f7f7;
  border: 1px solid #dedede;
}
.selectbox:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-size: 1rem;
  position: absolute;
  color: #ee3135;
  right: 0.75em;
  top: 1.1em;
  z-index: 1;
}

.selectbox > select {
  padding: 0.8em 0.4em;
  min-width: 100%;
  border: none;
  box-shadow: none;
  background: transparent;
  background-image: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  z-index: 2;
  position: relative;
}
.selectbox > select:focus {
  outline: none;
}
.selectbox > select::-ms-expand {
  display: none;
}

.site-credit {
  text-align: center;
}
.site-credit .title {
  margin-right: 0.5em;
  font-size: 1rem;
}
.site-credit .logo {
  width: 76px;
}
.site-credit .logo > img {
  width: 76px;
}
.site-credit .sponsor-additionaltext {
  padding-top: 20px;
}

.search-top-container .back-to-page {
  color: #d93253;
  transition: color 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
  padding: 0 2rem 0.625rem 2rem;
  display: inline-block;
}
.search-top-container .back-to-page:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  margin-right: 4px;
  position: relative;
  font-weight: bold;
  top: 1px;
}
.search-top-container .back-to-page:hover {
  color: #21caa4;
}

.search-filters {
  padding: 0.625rem 2rem;
  font-weight: bold;
}
.search-filters .search-term {
  padding-left: 2px;
  font-size: 1.125rem;
}
.search-filters .close {
  color: #d93253;
  transition: color 0.3s ease-in-out;
  display: inline-block;
  margin-left: 2px;
  font-size: 1.25rem;
  cursor: pointer;
}
.search-filters .close:hover {
  color: #21caa4;
}
.chapter-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 3.75rem;
  width: 3.75rem;
  font-size: 3.75rem;
  color: #d93253;
}
.chapter-nav .icon {
  position: relative;
  height: 3.75rem;
  width: 3.75rem;
  display: inline-block;
  cursor: pointer;
}
.chapter-nav .icon:before {
  display: inline-block;
  position: relative;
  top: -20%;
  background-color: #fff;
  border: solid 1.2px #dedede;
}
.chapter-nav .icon:hover .text-slide-out {
  max-width: 400px;
  z-index: 1;
  padding: 0 1.25rem;
  transition: padding 0.3s ease-in-out, max-width 0.3s ease-in-out;
}
.chapter-nav.next {
  right: 15%;
}
.chapter-nav.next .icon:before {
  transform: rotate(-90deg);
}
.chapter-nav.prev {
  left: 15%;
}
.chapter-nav.prev .icon:before {
  transform: rotate(90deg);
}
.chapter-nav .text-slide-out {
  font-size: 0.9375rem;
  font-weight: bold;
  height: 3.825rem;
  background-color: #fff;
  color: #333335;
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  line-height: 3.75rem;
  padding: 0;
  z-index: -1;
  top: -1.2px;
  overflow: hidden;
  max-width: 0;
  border: solid 1.2px #dedede;
  transition: padding 0.3s ease-in-out, max-width 0.3s ease-in-out, border 0.3s ease-in-out;
}
.chapter-nav .text-slide-out.next-text {
  right: 3.5rem;
}
.chapter-nav .text-slide-out.prev-text {
  left: 3.25rem;
}
@media screen and (max-width: 1024px) {
  .chapter-nav.next {
    right: 7.5%;
  }
  .chapter-nav.prev {
    left: 7.5%;
  }
}
@media screen and (max-width: 768px) {
  .chapter-nav {
    height: 2.5rem;
    width: 2.5rem;
    font-size: 2.5rem;
  }
  .chapter-nav.next {
    right: 2px;
  }
  .chapter-nav.prev {
    left: 0%;
  }
  .chapter-nav .icon {
    height: 2.5rem;
    width: 2.5rem;
  }
  .chapter-nav .text-slide-out {
    height: 2.5rem;
    line-height: 2.5rem;
    border: none;
  }
  .chapter-nav .text-slide-out.next-text {
    right: 2.5rem;
  }
  .chapter-nav .text-slide-out.prev-text {
    left: 2.125rem;
  }
}

.menu-button {
  height: 3.75rem;
  width: 3.75rem;
  background-color: #000;
  display: inline-block;
  position: relative;
  cursor: pointer;
  transition: height 0.3s ease-in-out;
}
.menu-button .menu-lines-container {
  height: 80%;
  width: 100%;
  position: relative;
}
.menu-button .menu-lines {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "";
  height: 2.5px;
  width: 30px;
  background-color: #fff;
  position: relative;
  display: block;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.menu-button .menu-lines:before, .menu-button .menu-lines:after {
  content: "";
  transition: all 0.3s ease-in-out;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #fff;
}
.menu-button .menu-lines:before {
  top: -7px;
}
.menu-button .menu-lines:after {
  bottom: -7px;
}
.menu-button .menu-lines.close {
  background-color: transparent;
}
.menu-button .menu-lines.close:before {
  transform: rotate(45deg);
  top: 0;
  background-color: #fff;
}
.menu-button .menu-lines.close:after {
  transform: rotate(-45deg);
  bottom: 0;
  background-color: #fff;
}
.menu-button .menu-text {
  text-transform: uppercase;
  color: #fff;
  font-size: 0.5625rem;
  font-weight: bold;
  position: absolute;
  bottom: 6px;
  width: 100%;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .menu-button {
    height: 3.25rem;
    width: 3.25rem;
  }
  .menu-button .menu-lines-container {
    height: 70%;
  }
}

.autocomplete-suggestions-wrapper {
  position: relative;
  margin: 0 auto;
  width: 50%;
}
.autocomplete-suggestions-wrapper .autocomplete-suggestions {
  background-color: #fff;
  padding: 0;
  display: none;
  position: absolute;
  text-rendering: optimizeLegibility;
  overflow-y: auto;
  max-height: 75vh;
  width: 100% !important;
  font-size: 0.85em;
  left: -1px;
  right: 0;
  top: 0;
  border-radius: 0 0 3px 3px;
  border-left: 1px solid #9b9b9b;
  border-right: 1px solid #9b9b9b;
  border-bottom: 1px solid #9b9b9b;
  box-shadow: 0 3px 3px 0 rgba(95, 95, 95, 0.6);
}
.autocomplete-suggestions-wrapper .autocomplete-suggestions .autocomplete-suggestion {
  list-style-type: none;
  font-size: 1rem;
  color: #d93253;
  background-color: #fff;
  text-align: left;
  font-weight: bold;
  position: relative;
  padding: 0.5625rem 1em 0.5625rem 1em;
  border-top: 1px solid #9b9b9b;
  cursor: pointer;
}
.autocomplete-suggestions-wrapper .autocomplete-suggestions .autocomplete-suggestion .label {
  float: none;
  text-transform: none;
}
.autocomplete-suggestions-wrapper .autocomplete-suggestions .autocomplete-suggestion .label .auto-term {
  font-weight: normal;
}
.autocomplete-suggestions-wrapper .autocomplete-suggestions .autocomplete-suggestion:hover {
  background-color: #d93253;
  color: #fff;
}
.autocomplete-suggestions-wrapper .autocomplete-suggestions .autocomplete-selected {
  background-color: #d93253;
  color: #fff;
}
.autocomplete-suggestions-wrapper .autocomplete-suggestions .autocomplete-hint {
  font-family: Muli, sans-serif;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .autocomplete-suggestions-wrapper {
    width: 100%;
  }
}

.glossary-term-trigger {
  color: #d93253;
  border-bottom: 1px #d93253 dotted;
  cursor: pointer;
  white-space: nowrap;
}

.glossary-modal {
  display: none;
  min-width: 200px;
  max-width: 400px;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 3px 3px 0 rgba(95, 95, 95, 0.6);
  background-color: #f7f7f7;
  z-index: 100;
}
.glossary-modal .glossary-container {
  position: relative;
  width: 100%;
}
.glossary-modal .glossary-close {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 0.75rem;
  cursor: pointer;
}
.glossary-modal .term-text {
  font-size: 20px;
  font-weight: bold;
  padding: 15px 15px 0 15px;
}
.glossary-modal .term-definition {
  font-size: 16px;
  padding: 10px 15px 15px 15px;
}
.glossary-modal .term-definition ul {
  padding-left: 40px;
  list-style-type: disc;
  list-style: disc;
}
.glossary-modal .term-definition ul ul {
  list-style-type: circle;
  list-style: circle;
}
.glossary-modal .term-definition ol {
  padding-left: 40px;
  list-style-type: decimal;
  list-style: decimal;
}
.glossary-modal .term-definition ol ol {
  list-style-type: lower-alpha;
  list-style: lower-alpha;
}
@media screen and (max-width: 630px) {
  .glossary-modal {
    width: 90vw;
  }
}

body.show-modal .glossary-modal {
  z-index: 1001;
}

.email-disclaimer {
  position: fixed;
  width: 90%;
  max-width: 830px;
  padding: 10px 50px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #f7f7f7;
  z-index: 130;
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms ease-in, visibility 300ms linear 300ms;
}
.is-active-modal .email-disclaimer {
  opacity: 1;
  visibility: visible;
  z-index: 130;
  transition: opacity 300ms ease-in, visibility 100ms linear;
}
.email-disclaimer .title {
  font-family: Muli, sans-serif;
  font-weight: bold;
  font-size: 28px;
  text-align: center;
  text-transform: uppercase;
}
@media screen and (max-width: 1024px) {
  .email-disclaimer .title {
    font-size: 24px;
  }
}
.email-disclaimer .copy {
  margin: 18px 0 32px;
  max-height: 52vh;
  overflow: auto;
  font-size: 14px;
}
.email-disclaimer .button-red + .button-red {
  margin-left: 25px;
}
.email-disclaimer .modal-close {
  position: absolute;
  bottom: 98%;
  left: 100%;
  margin-left: 7px;
  margin-bottom: 7px;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  color: #5f5f5f;
  background-color: #cececd;
  text-align: center;
}
@media screen and (max-width: 1024px) {
  .email-disclaimer {
    max-height: 85%;
    padding: 20px 30px;
  }
  .email-disclaimer .button-red + .button-red {
    margin-left: 5px;
  }
  .email-disclaimer .copy {
    height: 40vh;
    overflow: auto;
    font-size: 14px;
  }
  .email-disclaimer .modal-close {
    left: auto;
    right: 0;
    margin: 0 -15px -5px 0;
  }
}

.modal-bg {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  visibility: hidden;
  opacity: 0;
  transition: opacity 300ms ease-in, visibility 300ms linear 300ms;
}
.is-active-modal .modal-bg {
  opacity: 1;
  visibility: visible;
  z-index: 120;
  transition: opacity 300ms ease-in, visibility 100ms linear;
}

.external-window .email-disclaimer {
  width: 100%;
  height: 100%;
  padding: 10px 15px;
  background-color: #fdfdfd;
  opacity: 1;
  visibility: visible;
  z-index: 130;
}
.external-window .email-disclaimer .title {
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
}
@media screen and (max-width: 1024px) {
  .external-window .email-disclaimer .title {
    font-size: 24px;
  }
}
.external-window .email-disclaimer .actions {
  margin-bottom: 20px;
  margin-top: 20px;
}
.external-window .email-disclaimer .button-red + .button-red {
  margin-left: 25px;
}
@media screen and (max-width: 1024px) {
  .external-window .email-disclaimer .copy {
    height: auto;
    overflow: auto;
    font-size: 14px;
  }
}

.download-prompt-module {
  padding: 0.75em 0;
}

.download-prompt {
  box-sizing: border-box;
  color: #001a32;
  font-family: "BakerMcKenzieBernini", Arial Narrow, Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 32px;
  outline-color: #001a32;
  outline-style: none;
  outline-width: 0px;
  padding-top: 2rem;
  padding-left: 56px;
  padding-right: 56px;
  padding-top: 2rem;
  text-align: center;
  text-rendering: optimizelegibility;
}
.download-prompt .instructions {
  margin-bottom: 2em;
  font-weight: bold;
}
.download-prompt .button-red {
  background-attachment: scroll;
  background-clip: border-box;
  background-color: #ad122a;
  background-image: none;
  background-origin: padding-box;
  background-position: 0% 0%;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-size: auto;
  border-bottom-color: white;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  border-bottom-style: none;
  border-bottom-width: 0px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: white;
  border-left-style: none;
  border-left-width: 0px;
  border-right-color: white;
  border-right-style: none;
  border-right-width: 0px;
  border-top-color: white;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  border-top-style: none;
  border-top-width: 0px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: "BakerMcKenzieBernini", Arial Narrow, Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  outline-color: white;
  outline-style: none;
  outline-width: 0px;
  padding-bottom: 16px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 16px;
  text-align: center;
  text-rendering: optimizelegibility;
  text-transform: uppercase;
  transition-delay: 0s;
  transition-duration: 0.4s;
  transition-property: box-shadow;
  transition-timing-function: ease;
  vertical-align: baseline;
  -moz-appearance: button;
}

.translation-menu {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  color: #fff;
  padding: 0.5rem 1.25rem;
}
.translation-menu--home {
  padding-left: 0.625rem;
  position: relative;
  top: auto;
  left: auto;
}
.translation-menu__input-wrapper {
  position: relative;
}
.translation-menu__input-wrapper:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0.25rem;
}
.translation-menu__input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: inherit;
  font-weight: 700;
  background: transparent;
  border: none;
  padding-left: 0.5rem;
  padding-right: 1.25rem;
  position: relative;
  z-index: 1;
}
.translation-menu__input::-ms-expand {
  display: none;
}
.translation-menu__input option {
  color: #000;
}
@media screen and (max-width: 768px) {
  .translation-menu {
    z-index: 101;
  }
}
@media screen and (max-height: 420px) {
  .translation-menu {
    z-index: 101;
  }
}

.print-header {
  display: none;
}

@media print {
  .print-header {
    display: block;
    position: relative;
    z-index: 1001;
  }
  .print-header .logo-default {
    display: inline-flex;
    height: 60px;
    margin-left: 20px;
    transition: height 0.3s ease-in-out;
  }
  .print-header .logo-default img {
    transition: height 0.3s ease-in-out;
    height: 100%;
  }

  .global-menu,
.header,
.footer,
.word-cloud,
.topic-grid,
.topic-help,
.input-container,
.chapter-nav,
.in-page-navigation {
    display: none !important;
  }

  .answer-content {
    height: auto !important;
  }

  .topic-detail-container,
.chapter-intro-header,
.page-content,
.chapter-intro,
.general-content {
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .hero-section {
    padding-top: 0 !important;
  }

  .page-content,
.detail-section {
    margin-top: 20px !important;
  }
}
.escape-button {
  height: 3.75rem;
  width: 3.75rem;
  background-color: #fff;
  display: inline-block;
  position: relative;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  text-align: left;
}
.escape-button__red {
  background-color: #ee3135;
  top: inherit;
}
.escape-button__red span.escape-text {
  color: #fff;
}
.escape-button__red div.escape-lines-container .escape-x:before {
  color: #fff;
}
.escape-button .escape-lines-container {
  height: 80%;
  width: 100%;
  position: relative;
}
.escape-button .escape-lines-container .escape-x {
  display: block;
  height: 100%;
}
.escape-button .escape-lines-container .escape-x:before {
  content: "×";
  font-family: icomoon !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-size: 4rem;
  position: absolute;
  right: 0.18em;
  bottom: 0.005em;
  z-index: 1;
  color: #ee3135;
}
@media (max-width: 991px) {
  .escape-button .escape-lines-container .escape-x:before {
    right: 0.12em;
    bottom: 0.12em;
  }
}
.escape-button .escape-text {
  text-transform: uppercase;
  color: #b0182f;
  font-size: 0.5625rem;
  font-weight: bold;
  position: absolute;
  bottom: 4px;
  width: 100%;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .escape-button {
    height: 3.25rem;
    width: 3.25rem;
    top: 4px;
    right: 10px;
    position: absolute;
    z-index: 10;
  }
  .escape-button .escape-lines-container {
    height: 100%;
  }
}

.translation-menu + .print-header + .header + .home-escape {
  top: 140px;
}

.header + .home-escape-desktop {
  position: relative;
  top: 100px;
  width: 100%;
  z-index: 100;
}
@media (max-width: 991px) {
  .header + .home-escape-desktop {
    display: block;
    top: 22px;
    text-align: center;
  }
  .header + .home-escape-desktop .home-escape-message {
    position: absolute;
    top: 80px;
    height: 150px;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 10px 0;
    gap: 8px;
  }
  .header + .home-escape-desktop .home-escape-message-title {
    order: 1;
    width: 100%;
  }
  .header + .home-escape-desktop .home-escape-message-content {
    order: 2;
    margin-top: 5px;
  }
  .header + .home-escape-desktop .home-escape-message-button {
    order: 3;
  }
}

.home-escape {
  height: 80px;
}
.home-escape-message {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  color: white;
  background-color: #333335;
  height: 100%;
  width: 100%;
  padding: 14px 80px 14px 30px;
  gap: 30px;
}
@media screen and (max-width: 1450px) {
  .home-escape-message {
    margin: 0;
  }
  .home-escape-message-title {
    order: 1;
    width: 60%;
  }
  .home-escape-message-content {
    order: 3;
    margin-top: -50px;
  }
  .home-escape-message-button {
    order: 2;
  }
}
.home-escape-message-button {
  background-color: #ee3135;
  color: white;
  padding: 6px 36px;
}
.home-escape-message.home-escape-message-close {
  display: none;
}
.home-escape-mobile {
  display: none;
}
.home-escape-mobile .home-escape-message {
  position: absolute;
  top: 64px;
  height: 150px;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 10px 0;
  gap: 8px;
}
.home-escape-mobile .home-escape-message-title {
  order: 1;
  width: 100%;
}
.home-escape-mobile .home-escape-message-content {
  order: 2;
  margin-top: 5px;
}
.home-escape-mobile .home-escape-message-button {
  order: 3;
  margin-top: 8px;
}
.home-escape-mobile .home-escape-message:after {
  content: "";
  position: absolute;
  right: 2em;
  top: -0.3em;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #333335;
}
@media (max-width: 991px) {
  .home-escape-mobile {
    display: block;
  }
  .home-escape-desktop {
    display: none;
  }
}

.video-rte {
  position: relative;
  z-index: 2;
}
.video-rte-iframe {
  height: 400px;
  width: 830px;
}
@media (max-width: 991px) {
  .video-rte-iframe {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .video-rte-iframe {
    height: 200px;
  }
}

.video-rte + .locations {
  margin-top: 40px;
}

.video-modal {
  opacity: 1;
  visibility: visible;
  z-index: 130;
  -webkit-transition: opacity 0.3s ease-in, visibility 0.1s linear;
  transition: opacity 0.3s ease-in, visibility 0.1s linear;
  position: fixed;
  width: 90%;
  max-width: 830px;
  left: 50%;
  top: 40%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #f7f7f7;
  background-color: transparent;
  -webkit-transition: opacity 0.3s ease-in, visibility 0.3s linear 0.3s;
  transition: opacity 0.3s ease-in, visibility 0.3s linear 0.3s;
}
.video-modal .modal-close {
  position: absolute;
  top: -1.8em;
  right: 0.4em;
  color: transparent;
  background-color: transparent;
  z-index: 200;
  width: 24px;
  height: 24px;
}
@media (max-width: 991px) {
  .video-modal .modal-close {
    right: 0;
    top: -2.5em;
  }
}
.video-modal::after {
  content: "×";
  font-family: icomoon !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-size: 4rem;
  position: absolute;
  right: 0;
  top: -0.85em;
  z-index: 1;
  color: #9b9b9b;
}
@media (max-width: 991px) {
  .video-modal::after {
    right: -0.1em;
    top: -1em;
  }
}

/*******************************************
  Structures | larger that
********************************************/
.header {
  height: 6.25rem;
  width: 100%;
  position: fixed;
  background-color: #fff;
  z-index: 100;
  padding: 0 1.25rem;
  top: 0;
  display: flex;
  transition: height 0.3s ease-in-out;
  box-shadow: 0 3px 3px 0 rgba(95, 95, 95, 0.6);
}
.header.minimized {
  height: 3.5rem;
}
.header.minimized .escape-button .escape-lines {
  height: 2px;
  width: 24px;
  bottom: -20px;
}
.header.minimized .escape-button .escape-lines:before, .header.minimized .escape-button .escape-lines:after {
  height: 2px;
  width: 24px;
}
.header.minimized .escape-button .escape-lines:before {
  top: -5.6px;
}
.header.minimized .escape-button .escape-lines:after {
  bottom: -5.6px;
}
.header.minimized .escape-button .escape-lines.close {
  height: 0;
}
.header.minimized .escape-button .escape-lines.close:before {
  top: -2px;
}
.header.minimized .escape-button .escape-lines.close:after {
  bottom: 0;
}
.header.minimized .escape-button .escape-text {
  font-size: 0.45rem;
  bottom: 4px;
}
.header.minimized .logo-default {
  height: 45px;
}
.header.minimized .logo-default img {
  height: 100%;
}
.header.menu-active {
  position: relative;
}
.header .header-section-logo {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
}
.header .header-section-sponsors {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 4;
  padding-right: 70px;
}
.header .header-section-escape {
  width: 76px;
  height: 100%;
  display: flex;
  align-items: center;
}
.header .logo-default {
  display: inline-flex;
  height: 70px;
  margin-left: 20px;
  transition: height 0.3s ease-in-out;
}
.header .logo-default img {
  transition: height 0.3s ease-in-out;
  height: 100%;
}
.header .sponsors-text {
  font-size: 0.75rem;
  font-weight: bold;
  color: #474a48;
  display: inline-block;
}
.header .sponsor-image {
  display: inline-block;
  max-height: 2.625rem;
  margin-left: 20px;
}
.header .sponsor-image:last-child {
  max-width: 150px;
}
.header--with-translation-menu {
  top: 2.5rem;
  transition: height 0.3s ease-in-out, top 0.3s ease-in-out;
}
.header--with-translation-menu.minimized {
  top: 0;
}
.header--with-translation-menu.menu-active {
  top: 0;
}
@media screen and (max-width: 768px) {
  .header {
    position: relative;
    display: block;
    padding: 0;
  }
  .header .logo-default {
    height: 45px;
  }
  .header.minimized .escape-button .escape-lines {
    height: 2px;
    width: 24px;
    bottom: -22px;
  }
  .header.minimized .escape-button .escape-lines:before, .header.minimized .escape-button .escape-lines:after {
    height: 2px;
    width: 24px;
  }
  .header.minimized .escape-button .escape-lines:before {
    top: -5.6px;
  }
  .header.minimized .escape-button .escape-lines:after {
    bottom: -5.6px;
  }
  .header.minimized .escape-button .escape-lines.close {
    height: 0;
  }
  .header.minimized .escape-button .escape-lines.close:before {
    top: -2px;
  }
  .header.minimized .escape-button .escape-lines.close:after {
    bottom: 0;
  }
  .header.minimized .escape-button .escape-text {
    font-size: 0.45rem;
    bottom: 4px;
  }
  .header.minimized .logo-default {
    height: 45px;
  }
  .header.minimized .header-section-logo {
    height: 52px;
  }
  .header.minimized .menu-button .menu-text {
    font-size: 7px;
  }
  .header .header-section-logo {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 5rem;
    padding: 0 1.25rem;
    background-color: #fff;
    z-index: 10;
    transition: height 0.3s ease-in-out;
  }
  .header .header-section-sponsors {
    position: relative;
    top: 5rem;
    width: 100%;
    padding: 10px 1.25rem;
    background-color: #fff;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    z-index: 8;
    height: auto;
  }
  .header .sponsors-text {
    display: block;
    width: 100%;
    font-size: 1rem;
    margin-bottom: 4px;
  }
  .header .sponsor-image {
    margin-left: 0;
    padding-right: 1.25rem;
    margin-bottom: 4px;
    max-width: 33.333%;
  }
  .header--with-translation-menu {
    margin-top: 0;
  }
  .header--with-translation-menu.minimized {
    z-index: 102;
  }
  .header--with-translation-menu.minimized .header-section-logo {
    top: 0;
  }
  .header--with-translation-menu .header-section-logo {
    top: 31.78px;
    transition: height 0.3s ease-in-out, top 0.3s ease-in-out;
  }
}
@media screen and (max-height: 420px) {
  .header {
    position: relative;
    display: block;
    padding: 0;
  }
  .header .logo-default {
    height: 45px;
  }
  .header.minimized .escape-button .escape-lines {
    height: 2px;
    width: 24px;
    bottom: -22px;
  }
  .header.minimized .escape-button .escape-lines:before, .header.minimized .escape-button .escape-lines:after {
    height: 2px;
    width: 24px;
  }
  .header.minimized .escape-button .escape-lines:before {
    top: -5.6px;
  }
  .header.minimized .escape-button .escape-lines:after {
    bottom: -5.6px;
  }
  .header.minimized .escape-button .escape-lines.close {
    height: 0;
  }
  .header.minimized .escape-button .escape-lines.close:before {
    top: -2px;
  }
  .header.minimized .escape-button .escape-lines.close:after {
    bottom: 0;
  }
  .header.minimized .escape-button .escape-text {
    font-size: 0.45rem;
    bottom: 4px;
  }
  .header.minimized .logo-default {
    height: 45px;
  }
  .header.minimized .header-section-logo {
    height: 52px;
  }
  .header.minimized .menu-button .menu-text {
    font-size: 7px;
  }
  .header .header-section-logo {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 5rem;
    padding: 0 1.25rem;
    background-color: #fff;
    z-index: 10;
    transition: height 0.3s ease-in-out;
  }
  .header .header-section-sponsors {
    position: relative;
    top: 5rem;
    width: 100%;
    padding: 10px 1.25rem;
    background-color: #fff;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    z-index: 8;
    height: auto;
  }
  .header .sponsors-text {
    display: block;
    width: 100%;
    font-size: 1rem;
    margin-bottom: 4px;
  }
  .header .sponsor-image {
    margin-left: 0;
    padding-right: 1.25rem;
    margin-bottom: 4px;
    max-width: 33.333%;
  }
  .header--with-translation-menu {
    margin-top: 0;
  }
  .header--with-translation-menu.minimized {
    z-index: 102;
  }
  .header--with-translation-menu.minimized .header-section-logo {
    top: 0;
  }
  .header--with-translation-menu .header-section-logo {
    top: 31.78px;
    transition: height 0.3s ease-in-out, top 0.3s ease-in-out;
  }
}

.footer {
  position: relative;
  margin-top: 7.8125rem;
  min-height: 6.25rem;
  background-color: #333335;
  color: #fff;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.125rem;
  align-items: center;
  padding: 1.875rem;
}
.footer.home-footer {
  margin-top: 0;
}
.footer .footer-top {
  display: flex;
  justify-content: space-between;
}
.footer .footer-bottom {
  margin-left: 2.5rem;
  color: #fff;
  font-weight: normal;
  margin-top: 0.9375rem;
  font-size: 0.875rem;
}
.footer .footer-bottom.rich-text p {
  font-size: 14px;
}
.footer .footer-left,
.footer .footer-right {
  width: calc(50% - 20px);
  height: 40px;
}
.footer .footer-link,
.footer a {
  color: #fff;
  line-height: 40px;
  transition: opacity 0.3s linear;
}
.footer a:hover {
  opacity: 0.75;
}
.footer span.footer-link {
  font-weight: normal;
}
.footer ul.footer-links {
  list-style: none;
  height: 100%;
  margin-bottom: 0;
}
.footer ul.footer-links li {
  display: inline-block;
  height: 100%;
  line-height: 100%;
}
.footer ul.footer-links li + li:before {
  content: "|";
  margin: 0 8px 0 4px;
}
.footer .footer-right {
  text-align: right;
}
.footer .footer-right p {
  margin-bottom: 0;
  line-height: 40px;
}
.footer .footer-right img {
  margin-left: 10px;
}
@media screen and (max-width: 1024px) {
  .footer {
    height: auto;
  }
  .footer .footer-top {
    display: block;
  }
  .footer .footer-bottom {
    margin-left: 0;
  }
  .footer .footer-left,
.footer .footer-right {
    width: 100%;
    height: auto;
    text-align: center;
  }
  .footer ul.footer-links {
    padding-left: 0;
    margin-left: 0;
  }
  .footer a,
.footer p {
    line-height: 1.5;
  }
}

.sidebar-sections .section {
  padding: 80px 40px 0 40px;
}
.sidebar-sections .section.secondary-section {
  padding-top: 3.75rem;
}
@media screen and (max-width: 1024px) {
  .sidebar-sections .section {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.site-tagline {
  font-size: 2.25rem;
  margin-bottom: 1em;
}

.site-description {
  font-size: 0.875rem;
  margin-bottom: 2em;
}

.home-header {
  text-align: center;
}
.home-header .header-section-menu {
  display: none;
}
.home-header .menu-button {
  position: absolute;
  top: 10px;
  left: 10px;
  text-align: left;
}
@media screen and (max-width: 1024px) {
  .home-header.section {
    padding-left: 0;
  }
}

.page {
  min-height: 66vh;
  min-width: 90vw;
}
.page .background-image {
  position: fixed;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: #fff;
}
@media screen and (max-width: 1024px) {
  .page {
    min-height: 50vh;
  }
}
@media screen and (max-width: 768px) {
  .page {
    min-height: 37vh;
  }
}

/*******************************************
  COLUMNS | grid structures
*******************************************/
[class^=columns-],
[class*=" columns-"] {
  display: flex;
  flex-wrap: wrap;
  margin-right: -6.5px;
  margin-left: -6.5px;
}
@media (min-width: 576px) {
  [class^=columns-],
[class*=" columns-"] {
    margin-right: -6.5px;
    margin-left: -6.5px;
  }
}
@media (min-width: 768px) {
  [class^=columns-],
[class*=" columns-"] {
    margin-right: -6.5px;
    margin-left: -6.5px;
  }
}
@media (min-width: 992px) {
  [class^=columns-],
[class*=" columns-"] {
    margin-right: -6.5px;
    margin-left: -6.5px;
  }
}
@media (min-width: 1200px) {
  [class^=columns-],
[class*=" columns-"] {
    margin-right: -6.5px;
    margin-left: -6.5px;
  }
}

.column {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 6.5px;
  padding-left: 6.5px;
}
@media (min-width: 576px) {
  .column {
    padding-right: 6.5px;
    padding-left: 6.5px;
  }
}
@media (min-width: 768px) {
  .column {
    padding-right: 6.5px;
    padding-left: 6.5px;
  }
}
@media (min-width: 992px) {
  .column {
    padding-right: 6.5px;
    padding-left: 6.5px;
  }
}
@media (min-width: 1200px) {
  .column {
    padding-right: 6.5px;
    padding-left: 6.5px;
  }
}

.columns-3 .column {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
@media screen and (max-width: 1024px) {
  .columns-3 .column {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media screen and (max-width: 768px) {
  .columns-3 .column {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.chapter-intro {
  text-align: center;
  padding: 0rem 5rem;
  margin-top: 1.875rem;
  padding-bottom: 1.875rem;
  position: relative;
  z-index: 11;
}
@media screen and (max-width: 1024px) {
  .chapter-intro {
    padding: 0rem 3.75rem;
  }
}
@media screen and (max-width: 768px) {
  .chapter-intro {
    padding: 0rem 1.875rem;
  }
}
.chapter-intro .chapter-intro-header {
  width: 68%;
  margin: 0 auto;
}
.chapter-intro h1 {
  font-size: 1.75rem;
  margin-bottom: 1.25rem;
}
.chapter-intro .drop-down-trigger {
  color: #d93253;
  cursor: pointer;
  font-weight: bold;
  outline: none;
}
.chapter-intro .drop-down-trigger:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  margin-left: 4px;
  position: relative;
  top: 4px;
}
.chapter-intro .drop-down-trigger.expanded:after {
  transform: rotate(180deg);
}
.chapter-intro .drop-down-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 950px;
  background-color: #fff;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  box-shadow: 0 3px 3px 0 rgba(95, 95, 95, 0.5);
  z-index: 5;
}
.chapter-intro .drop-down-container.active {
  max-height: 1000px;
  transition: max-height 0.5s ease-in-out;
}
.chapter-intro .drop-down-container .drop-down-heading {
  padding: 1.875rem 1.875rem 0.625rem 1.875rem;
  text-align: center;
}
.chapter-intro .drop-down-container .drop-down-list {
  padding: 0 20px 0 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  list-style-type: none;
  list-style: none;
}
.chapter-intro .drop-down-container .drop-down-item {
  display: inline-block;
  margin: 15px;
  width: calc(33.3333% - 30px);
}
.chapter-intro .drop-down-container .drop-down-item a {
  display: block;
  text-align: center;
}
.chapter-intro .drop-down-container .drop-down-item a:hover {
  color: #d93253;
}
.chapter-intro .rich-text {
  width: 60%;
  margin: 0 auto;
}
@media screen and (max-width: 1024px) {
  .chapter-intro .chapter-intro-header {
    width: 75%;
  }
  .chapter-intro .rich-text {
    width: 80%;
  }
  .chapter-intro .drop-down-container {
    width: 80vw;
  }
}
@media screen and (max-width: 768px) {
  .chapter-intro .chapter-intro-header {
    width: 90%;
  }
}
@media screen and (max-width: 630px) {
  .chapter-intro .drop-down-container {
    width: 100%;
  }
  .chapter-intro .drop-down-container.active {
    max-height: 60vh;
    overflow-y: auto;
  }
  .chapter-intro .drop-down-container .drop-down-list {
    padding-top: 0;
  }
  .chapter-intro .drop-down-container .drop-down-item {
    display: block;
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .chapter-intro .drop-down-container .drop-down-item:first-child {
    margin-top: 0;
  }
}

.rich-text {
  color: #262626;
}
.rich-text p {
  font-size: 1rem;
  line-height: 1.6875rem;
}
.rich-text img {
  max-width: 100%;
  height: auto;
}
.rich-text .video-wrapper {
  float: none;
  clear: both;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}
.rich-text .video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1024px) {
  .rich-text table {
    width: 100% !important;
  }
}

.global-menu {
  position: fixed;
  left: -480px;
  top: 0;
  width: 480px;
  height: 100vh;
  z-index: 1;
  background-color: #333335;
  color: #fff;
  overflow: hidden;
}
.global-menu .menu-list-container {
  overflow-y: auto;
  max-height: 75vh;
}
.global-menu .menu-handbook-link {
  padding: 1rem 2.5rem;
  display: block;
}
.global-menu .menu-handbook-link a {
  color: #fff;
  font-size: 1.125rem;
  position: relative;
  display: inline-block;
}
.global-menu .menu-handbook-link a:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -25px;
}
.global-menu .menu-content .top-content {
  display: block;
  height: 2.5rem;
  color: #dedede;
  padding: 1.25rem 2.5rem;
  transition: padding 0.4s ease-in-out;
}
.global-menu .menu-content .top-content .home-logo {
  color: #dedede;
}
.global-menu .menu-content .menu-title {
  font-size: 1.9375rem;
  font-weight: bold;
  display: block;
  margin-bottom: 2.5rem;
  padding: 0 2.5rem;
}
.global-menu .menu-content .main-content {
  padding: 1.25rem 0;
}
.global-menu .menu-content .location-name {
  font-size: 0.75rem;
  color: #9b9b9b;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0.875rem;
  padding: 0 2.5rem;
}
.global-menu .menu-content ul {
  list-style: none;
  padding-left: 0;
  font-size: 1.125rem;
  margin-bottom: 3.75rem;
}
.global-menu .menu-content ul:last-child {
  margin-bottom: 0;
}
.global-menu .menu-content ul li.location-item {
  display: block;
  color: #fff;
  border-bottom: solid 1px #262626;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}
.global-menu .menu-content ul li.location-item a {
  color: #fff;
  display: block;
  padding: 0.875rem 2.5rem;
}
.global-menu .menu-content ul li.location-item:hover, .global-menu .menu-content ul li.location-item.active-page {
  background-color: #4c4c4f;
}
.global-menu .icon-home {
  font-size: 1.625rem;
}
.global-menu.secondary-menu-open .menu-container {
  left: -3.75rem;
}
.global-menu.secondary-menu-open .primary-menu .top-content {
  padding-left: 0.9375rem;
}
.global-menu.secondary-menu-open .primary-menu .main-content {
  visibility: hidden;
  opacity: 0;
}
.global-menu .menu-container {
  overflow: hidden;
  position: relative;
  width: 200%;
  display: flex;
  left: -100%;
  transition: left 0.4s ease-in-out;
}
.global-menu .primary-menu {
  position: relative;
  width: 100%;
}
.global-menu .primary-menu ul li.location-item .active-menu-arrow {
  display: none;
}
.global-menu .primary-menu ul li.location-item.active-page {
  position: relative;
}
.global-menu .primary-menu ul li.location-item.active-page .active-menu-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  right: 2px;
}
.global-menu .primary-menu ul li.location-item.active-page .active-menu-arrow:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-size: 1.75rem;
  transform: rotate(-90deg);
}
.global-menu .secondary-menu {
  position: relative;
  width: 100%;
  background-color: #474a48;
}
.global-menu .secondary-menu .top-content {
  color: #fff;
}
.global-menu .secondary-menu .top-content span {
  cursor: pointer;
}
.global-menu .secondary-menu.menu-content ul li.location-item:hover, .global-menu .secondary-menu.menu-content ul li.location-item.active-page {
  background-color: #656569;
}
.global-menu .secondary-container {
  width: 100%;
  padding-left: 3.75rem;
}
.global-menu .secondary-container .main-content {
  display: none;
}
.global-menu .secondary-container .main-content.visible {
  display: block;
  overflow: auto;
}
.global-menu .secondary-container .icon-arrow-left:before {
  margin-right: 4px;
  position: relative;
  top: 1px;
}
@media screen and (max-width: 768px) {
  .global-menu {
    width: 80vw;
    left: -80vw;
  }
}
@media screen and (max-height: 900px) {
  .global-menu .menu-list-container {
    max-height: 70vh;
  }
}
@media screen and (max-height: 420px) {
  .global-menu .menu-list-container {
    max-height: 50vh;
  }
}

.hero-section {
  background-color: transparent;
  width: 100%;
  text-align: center;
  padding-top: 6.25rem;
}
.hero-section .handbook-header-name {
  color: #fff;
  opacity: 0;
  font-size: 4rem;
}
.hero-section .icon-search {
  color: #d93253;
  top: 2px;
  position: relative;
}
.hero-section .input-container {
  width: 50%;
}
@media screen and (max-width: 768px) {
  .hero-section {
    padding-top: 3.125rem;
  }
  .hero-section .input-container {
    width: 100%;
  }
  .hero-section .handbook-header-name {
    font-size: 3rem;
  }
}

.detail-section {
  margin-top: 60px;
  width: 100%;
  background-color: #f7f7f7;
  padding: 1.25rem 0 0;
  display: none;
  opacity: 0;
}
.detail-section .tagline {
  text-align: center;
  margin-bottom: 2.5rem;
  margin-top: 1.25rem;
  padding: 0rem 5rem;
}
@media screen and (max-width: 1024px) {
  .detail-section .tagline {
    padding: 0rem 3.75rem;
  }
}
@media screen and (max-width: 768px) {
  .detail-section .tagline {
    padding: 0rem 1.875rem;
  }
}
.detail-section.active-section, .detail-section.only-section {
  display: block;
}
.detail-section.active-section.gc-detail-section, .detail-section.only-section.gc-detail-section {
  display: flex;
  padding: 5rem 5rem;
}
@media screen and (max-width: 1024px) {
  .detail-section.active-section.gc-detail-section, .detail-section.only-section.gc-detail-section {
    padding: 5rem 3.75rem;
  }
}
@media screen and (max-width: 768px) {
  .detail-section.active-section.gc-detail-section, .detail-section.only-section.gc-detail-section {
    padding: 5rem 1.875rem;
  }
}
@media screen and (max-width: 768px) {
  .detail-section.active-section.gc-detail-section, .detail-section.only-section.gc-detail-section {
    display: block;
    padding-top: 0;
  }
}
.detail-section.only-section {
  opacity: 1;
}

.portal-grid {
  padding: 0rem 5rem;
}
@media screen and (max-width: 1024px) {
  .portal-grid {
    padding: 0rem 3.75rem;
  }
}
@media screen and (max-width: 768px) {
  .portal-grid {
    padding: 0rem 1.875rem;
  }
}
.portal-grid .portal-grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-right: -13px;
  list-style: none;
  list-style-type: none;
  padding-left: 0;
}
.portal-grid .grid-item {
  width: calc(25% - 20px);
  display: inline-block;
  background-color: #fff;
  text-align: center;
  padding: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
  opacity: 0;
  cursor: pointer;
  position: relative;
}
.portal-grid .grid-item a {
  display: block;
  width: 100%;
  height: 100%;
}
.portal-grid .icon-container:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  transition: height 0.3s ease-in-out;
  z-index: -1;
}
.portal-grid .item-icon {
  padding: 20px;
  height: 120px;
  position: relative;
  margin-bottom: 20px;
}
.portal-grid .item-icon img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 80px;
  max-width: 100%;
}
.portal-grid .item-icon .hover-icon {
  display: none;
}
.portal-grid .item-name {
  border-top: solid 1.2px #dedede;
  padding: 16px 0 0 0;
  font-size: 1.25rem;
  font-weight: bold;
  color: #474a48;
  line-height: 1.625rem;
}
.portal-grid .item-name.no-icon {
  height: 100%;
  border-top: none;
  min-height: 150px;
  padding-top: 0;
  transition: color 0.3s ease-in-out;
}
.portal-grid .item-name.no-icon:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  transition: height 0.3s ease-in-out;
  z-index: -1;
}
.portal-grid .item-name span {
  min-height: 50px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1024px) {
  .portal-grid .grid-item {
    width: calc(33.333% - 20px);
    padding: 20px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 768px) {
  .portal-grid .grid-item {
    width: calc(50% - 20px);
    padding: 20px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 630px) {
  .portal-grid .portal-grid-container {
    margin-right: 0;
    padding-left: 20px;
  }
  .portal-grid .grid-item {
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
  }
}

html.no-touch .portal-grid .grid-item:hover .has-hover:before {
  height: 160px;
}
html.no-touch .portal-grid .grid-item:hover .has-hover .item-icon .hover-icon {
  display: block;
}
html.no-touch .portal-grid .grid-item:hover .has-hover .item-icon .default-icon {
  display: none;
}
html.no-touch .portal-grid .grid-item:hover .no-icon {
  color: #fff;
}
html.no-touch .portal-grid .grid-item:hover .no-icon:before {
  height: 100%;
}

.topic-grid {
  padding: 0rem 5rem;
  position: relative;
  z-index: 10;
}
@media screen and (max-width: 1024px) {
  .topic-grid {
    padding: 0rem 3.75rem;
  }
}
@media screen and (max-width: 768px) {
  .topic-grid {
    padding: 0rem 1.875rem;
  }
}
.topic-grid .grid-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 30px;
  list-style: none;
  list-style-type: none;
  padding-left: 0;
  justify-content: center;
}
.topic-grid .topic-item {
  display: inline-block;
  width: calc(33.3333% - 10px);
  margin-bottom: 10px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #dedede;
  overflow: visible;
  position: relative;
  opacity: 0;
  margin-right: 0.625rem;
}
.topic-grid .topic-item:hover {
  z-index: 10;
}
.topic-grid .topic-container {
  position: relative;
  height: 100%;
  width: 100%;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  cursor: pointer;
  padding: 0.875rem 1.5rem;
  min-height: 6rem;
  display: flex;
  justify-content: center;
  align-content: center;
}
.topic-grid .topic-container.active {
  background-color: #21caa4;
}
.topic-grid .topic-container.active .topic-name {
  color: #fff;
}
.topic-grid .topic-name {
  font-size: 1.375rem;
  color: #21caa4;
  line-height: 1.2;
  transition: color 0.3s ease-in-out;
  font-weight: bold;
  display: block;
}
.topic-grid .topic-description {
  font-size: 0.875rem;
  color: #21caa4;
  height: 0;
  opacity: 0;
  display: none;
  transition: height 0.3s ease-in-out, color 0.3s ease-in-out;
  padding: 0 1.25rem;
}
@media screen and (max-width: 1024px) {
  .topic-grid .topic-item {
    width: calc(50% - 10px);
  }
}
@media screen and (max-width: 768px) {
  .topic-grid .topic-item {
    width: 100%;
  }
}

html.no-touch .topic-grid .topic-container.tile-hovered:not(.active):hover {
  transform: scale(1.3);
  background-color: #21caa4;
}
html.no-touch .topic-grid .topic-container.tile-hovered:not(.active):hover .topic-name {
  color: #fff;
  font-size: 1.0576923077rem;
}
html.no-touch .topic-grid .topic-container.tile-hovered:not(.active):hover .topic-description {
  opacity: 1;
  height: auto;
  color: #fff;
  margin-top: 4px;
  display: block;
  font-size: 0.6730769231rem;
}

.button__download .topic-name {
  color: #fff !important;
}
.button__download .download_icon {
  padding-left: 0.5rem;
  height: 1.375rem;
}

.search-result-container {
  padding: 1.25rem 5rem;
  width: 100%;
  background-color: #fff;
}
@media screen and (max-width: 1024px) {
  .search-result-container {
    padding: 1.25rem 3.75rem;
  }
}
@media screen and (max-width: 768px) {
  .search-result-container {
    padding: 1.25rem 1.875rem;
  }
}
.search-result-container .search-result {
  display: block;
  padding: 2.5rem 0;
  border-bottom: solid 1.2px #dedede;
  opacity: 0;
}
.search-result-container .result-title {
  color: #21caa4;
  font-size: 1.375rem;
  font-weight: bold;
  margin-bottom: 8px;
}
.search-result-container .tag {
  color: #d93253;
  font-weight: bold;
  font-size: 0.75rem;
}
.search-result-container .tag + .tag:before {
  content: "/";
  color: #333335;
  margin: 0 8px 0 4px;
}
.search-result-container .view-more-container {
  text-align: center;
  margin: 1.875rem 0;
}
.search-result-container .no-handbook-search-results {
  font-size: 1.125rem;
  padding: 1.875rem 0;
}

.word-cloud {
  background-color: #fff;
  text-align: center;
  padding: 5rem 5rem;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  font-size: 1.75rem;
  color: #d93253;
}
@media screen and (max-width: 1024px) {
  .word-cloud {
    padding: 5rem 3.75rem;
  }
}
@media screen and (max-width: 768px) {
  .word-cloud {
    padding: 5rem 1.875rem;
  }
}
.word-cloud .tag-container {
  width: 100%;
  margin: 0 auto;
}
.word-cloud .tag-container.container-active .tag {
  opacity: 0.15;
  transform: scale(0.7);
}
.word-cloud .tag {
  white-space: nowrap;
  line-height: 48px;
  display: inline-block;
  padding: 4px 8px;
  cursor: pointer;
  opacity: 0;
}
.word-cloud .tag.initialized {
  opacity: 1;
  transition: all 0.4s ease-in-out;
}
.word-cloud .tag:nth-child(5n+1) {
  font-size: 4rem;
}
.word-cloud .tag:nth-child(5n+2) {
  font-size: 2rem;
}
.word-cloud .tag:nth-child(5n+3) {
  font-size: 2.8rem;
}
.word-cloud .tag:nth-child(5n+4) {
  font-size: 1.6rem;
}
.word-cloud .tag:nth-child(5n) {
  font-size: 3.2rem;
}
.word-cloud .tag:nth-child(4n) {
  color: #f1c13c;
}
.word-cloud .tag:nth-child(4n+1) {
  color: #d93253;
}
.word-cloud .tag:nth-child(4n+2) {
  color: #a2c845;
}
.word-cloud .tag:nth-child(4n+3) {
  color: #395dd8;
}
@media screen and (max-width: 1024px) {
  .word-cloud .tag-container {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .word-cloud .tag:nth-child(5n+1) {
    font-size: 2rem;
  }
  .word-cloud .tag:nth-child(5n+2) {
    font-size: 1rem;
  }
  .word-cloud .tag:nth-child(5n+3) {
    font-size: 1.4rem;
  }
  .word-cloud .tag:nth-child(5n+4) {
    font-size: 0.8rem;
  }
  .word-cloud .tag:nth-child(5n) {
    font-size: 1.6rem;
  }
  .word-cloud .tag {
    line-height: 24px;
  }
}

html.no-touch .word-cloud .tag-container.container-active .tag:hover {
  opacity: 1;
  transform: scale(1.4);
  border-radius: 15px;
  z-index: 3;
}
html.no-touch .word-cloud .tag:nth-child(4n):hover {
  background-color: #f1c13c;
  color: #fff;
}
html.no-touch .word-cloud .tag:nth-child(4n+1):hover {
  background-color: #d93253;
  color: #fff;
}
html.no-touch .word-cloud .tag:nth-child(4n+2):hover {
  background-color: #a2c845;
  color: #fff;
}
html.no-touch .word-cloud .tag:nth-child(4n+3):hover {
  background-color: #395dd8;
  color: #fff;
}

.topic-help {
  padding: 2.5rem 5rem;
  background-color: transparent;
}
@media screen and (max-width: 1024px) {
  .topic-help {
    padding: 2.5rem 3.75rem;
  }
}
@media screen and (max-width: 768px) {
  .topic-help {
    padding: 2.5rem 1.875rem;
  }
}
.topic-help .input-container {
  max-width: 90%;
  width: 425px;
  background-color: transparent;
  border-bottom: solid 1.2px #dedede;
}
.topic-help .input-container input {
  background-color: transparent;
}
.topic-help .input-container input:after {
  content: "X";
  font-weight: bold;
  font-size: 1.5rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.topic-help .input-container .topic-help-icon {
  color: #d93253;
  position: relative;
  top: 3px;
}
@media screen and (max-width: 630px) {
  .topic-help .input-container {
    width: 99%;
    max-width: 99%;
  }
  .topic-help .input-container input {
    width: 92%;
  }
}

.topic-detail-container {
  padding: 0rem 10rem;
  max-width: 100rem;
  margin: 0 auto;
  padding-bottom: 3.125rem;
  padding-top: 1.875rem;
}
@media screen and (max-width: 1024px) {
  .topic-detail-container {
    padding: 0rem 7.5rem;
  }
}
@media screen and (max-width: 768px) {
  .topic-detail-container {
    padding: 0rem 3.75rem;
  }
}
@media screen and (max-width: 1024px) {
  .topic-detail-container {
    padding-bottom: 3.125rem;
    padding-top: 1.875rem;
  }
}
.topic-detail-container .topic-detail-title {
  text-align: center;
  margin-bottom: 2.1875rem;
}
.topic-detail-container .questions-container {
  border-bottom: solid 1.2px #dedede;
}
.topic-detail-container .answer-content {
  height: 0;
  transition: height 0.5s linear;
  overflow: hidden;
}
.topic-detail-container .question {
  transition: height 0.5s linear;
}
.topic-detail-container .question.expanded .answer-content {
  height: auto;
}
.topic-detail-container .question.expanded .question-title:after {
  content: "-";
}
.topic-detail-container .question-expander {
  border-top: solid 1.2px #dedede;
  padding: 1.875rem 0;
  cursor: pointer;
  position: relative;
  width: 100%;
  outline: none;
  text-align: left;
}
.topic-detail-container .question-title {
  font-size: 1.5rem;
  font-weight: bold;
  width: 90%;
}
.topic-detail-container .question-title:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  content: "+";
  right: 25px;
  font-size: 2.5rem;
  font-weight: bold;
}
.topic-detail-container .sub-topics-container {
  margin-top: 5em;
}
.topic-detail-container .sub-topic-item {
  margin-top: 5em;
}
.topic-detail-container .sub-topic-item:first-child {
  margin-top: 0;
}
.topic-detail-container .sub-topics-tabs-container {
  margin-bottom: 2em;
}
.topic-detail-container .sub-topics-tabs-container .mobile-dropdown-trigger {
  display: none;
}
.topic-detail-container .sub-topics-tabs-list {
  list-style: none;
  list-style-type: none;
  text-align: center;
  padding: 0;
  margin: 0;
}
.topic-detail-container .sub-topics-tabs-list .sub-topic-tab {
  padding: 1em;
  margin: 0 1em 1em 0;
  border: 1px solid #21caa4;
  background-color: #fff;
  transition: background-color 0.3s ease-in-out;
  display: inline-block;
  font-weight: bold;
  font-size: 0.875rem;
  cursor: pointer;
}
.topic-detail-container .sub-topics-tabs-list .sub-topic-tab button {
  outline: none;
  color: #21caa4;
  font-weight: bold;
  display: block;
  width: 100%;
  height: 100%;
}
.topic-detail-container .sub-topics-tabs-list .sub-topic-tab:hover, .topic-detail-container .sub-topics-tabs-list .sub-topic-tab.active-tab {
  background-color: #21caa4;
}
.topic-detail-container .sub-topics-tabs-list .sub-topic-tab:hover button, .topic-detail-container .sub-topics-tabs-list .sub-topic-tab.active-tab button {
  color: #fff;
}
.topic-detail-container.word-cloud-results .topic-detail {
  padding-top: 3.125rem;
}
.topic-detail-container.word-cloud-results .no-wordcloud-results {
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .topic-detail-container .sub-topics-tabs-container {
    display: block;
    width: 100%;
    padding-top: 1.25rem;
    position: relative;
  }
  .topic-detail-container .sub-topics-tabs-container .mobile-dropdown-trigger {
    display: block;
    width: 100%;
    background-color: #fff;
    padding: 0.9375rem 3.4375rem 0.9375rem 1.25rem;
    font-weight: bold;
    color: #474a48;
    font-size: 18px;
    position: relative;
    margin: 0 auto;
    cursor: pointer;
  }
  .topic-detail-container .sub-topics-tabs-container .mobile-dropdown-trigger:after {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e905";
    display: inline-block;
    margin-left: 4px;
    position: absolute;
    top: 12px;
    right: 20px;
    font-size: 28px;
  }
  .topic-detail-container .sub-topics-tabs-container .mobile-dropdown-trigger.expanded:after {
    transform: rotate(180deg);
  }
  .topic-detail-container .sub-topics-tabs-container ul {
    max-height: 0;
    overflow: hidden;
    background-color: #fff;
    position: absolute;
    top: 65px;
    left: 0;
    width: 100%;
    box-shadow: 0 3px 3px 0 rgba(95, 95, 95, 0.6);
    z-index: 1;
    transition: max-height 0.3s ease-in-out;
  }
  .topic-detail-container .sub-topics-tabs-container ul.active {
    max-height: 70vh;
    overflow: auto;
  }
  .topic-detail-container .sub-topics-tabs-container ul li {
    padding: 0;
    border: none;
  }
  .topic-detail-container .sub-topics-tabs-container ul li a {
    padding: 0.9375rem;
    border-bottom: solid 1px #dedede;
  }
  .topic-detail-container .sub-topics-tabs-container ul li a.active {
    display: none;
  }
  .topic-detail-container .sub-topics-tabs-container ul li a:hover {
    background-color: #e8e8e8;
  }
  .topic-detail-container .sub-topics-tabs-container .sub-topics-tabs-list .sub-topic-tab {
    margin-left: 1rem;
    margin-right: 1rem;
    display: block;
  }
  .topic-detail-container .topic-detail-title h3 {
    font-size: 2.0625rem;
  }
}
@media screen and (max-width: 630px) {
  .topic-detail-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .topic-detail-container .question-title {
    width: 85%;
  }
  .topic-detail-container .question-title:after {
    right: 10px;
  }
}

/*******************************************
  PAGES | page specific alterations
********************************************/
.page-home {
  min-height: 91vh;
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
}
@media (min-width: 576px) {
  .page-home {
    margin-right: 0px;
    margin-left: 0px;
  }
}
@media (min-width: 768px) {
  .page-home {
    margin-right: 0px;
    margin-left: 0px;
  }
}
@media (min-width: 992px) {
  .page-home {
    margin-right: 0px;
    margin-left: 0px;
  }
}
@media (min-width: 1200px) {
  .page-home {
    margin-right: 0px;
    margin-left: 0px;
  }
}
.page-home .column-main,
.page-home .column-sidebar {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0px;
  padding-left: 0px;
}
@media (min-width: 576px) {
  .page-home .column-main,
.page-home .column-sidebar {
    padding-right: 0px;
    padding-left: 0px;
  }
}
@media (min-width: 768px) {
  .page-home .column-main,
.page-home .column-sidebar {
    padding-right: 0px;
    padding-left: 0px;
  }
}
@media (min-width: 992px) {
  .page-home .column-main,
.page-home .column-sidebar {
    padding-right: 0px;
    padding-left: 0px;
  }
}
@media (min-width: 1200px) {
  .page-home .column-main,
.page-home .column-sidebar {
    padding-right: 0px;
    padding-left: 0px;
  }
}
.page-home .column-main {
  flex: 0 0 75%;
  max-width: 75%;
}
@media (max-width: 991px) {
  .page-home .column-main {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.page-home .column-sidebar {
  flex: 0 0 25%;
  max-width: 25%;
}
@media (max-width: 991px) {
  .page-home .column-sidebar {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.page-general .in-page-navigation {
  display: inline-block;
  width: 20%;
  min-width: 200px;
}
.page-general .in-page-navigation .mobile-dropdown-trigger {
  display: none;
}
.page-general .in-page-navigation ul {
  list-style: none;
  list-style-type: none;
  padding: 0;
  display: block;
}
.page-general .in-page-navigation ul li {
  border-bottom: solid 1px #dedede;
  padding: 0.9375rem 0;
}
.page-general .in-page-navigation ul li:first-child {
  padding-top: 3px;
}
.page-general .in-page-navigation ul li:last-child {
  border: none;
}
.page-general .in-page-navigation ul li .sub-menu-link {
  font-weight: bold;
  display: block;
  color: #b0182f;
  font-size: 18px;
  transition: color 0.3s linear;
}
.page-general .in-page-navigation ul li .sub-menu-link.active {
  color: #333335;
}
.page-general .in-page-navigation ul li a:hover {
  color: #474a48;
}
.page-general .general-content {
  display: inline-block;
  width: calc(100% - 200px);
  padding-left: 60px;
  max-width: 100ch;
}
.page-general h1 {
  color: #333335;
  margin-bottom: 1.25rem;
  font-size: 3rem;
}
@media screen and (max-width: 768px) {
  .page-general .in-page-navigation {
    display: block;
    width: 100%;
    padding-top: 1.25rem;
    position: relative;
  }
  .page-general .in-page-navigation .mobile-dropdown-trigger {
    display: block;
    width: 100%;
    background-color: #fff;
    padding: 0.9375rem 3.4375rem 0.9375rem 1.25rem;
    font-weight: bold;
    color: #474a48;
    font-size: 18px;
    position: relative;
    margin: 0 auto;
    cursor: pointer;
  }
  .page-general .in-page-navigation .mobile-dropdown-trigger:after {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e905";
    display: inline-block;
    margin-left: 4px;
    position: absolute;
    top: 12px;
    right: 20px;
    font-size: 28px;
  }
  .page-general .in-page-navigation .mobile-dropdown-trigger.expanded:after {
    transform: rotate(180deg);
  }
  .page-general .in-page-navigation ul {
    max-height: 0;
    overflow: hidden;
    background-color: #fff;
    position: absolute;
    top: 65px;
    left: 0;
    width: 100%;
    box-shadow: 0 3px 3px 0 rgba(95, 95, 95, 0.6);
    z-index: 1;
    transition: max-height 0.3s ease-in-out;
  }
  .page-general .in-page-navigation ul.active {
    max-height: 70vh;
    overflow: auto;
  }
  .page-general .in-page-navigation ul li {
    padding: 0;
    border: none;
  }
  .page-general .in-page-navigation ul li a {
    padding: 0.9375rem;
    border-bottom: solid 1px #dedede;
  }
  .page-general .in-page-navigation ul li a.active {
    display: none;
  }
  .page-general .in-page-navigation ul li a:hover {
    background-color: #e8e8e8;
  }
  .page-general .in-page-navigation .sub-menu-link.active {
    display: none;
  }
  .page-general .general-content {
    display: block;
    width: 100%;
    padding-left: 0;
    padding-top: 2.5rem;
  }
  .page-general h1 {
    font-size: 2.25rem;
    margin-bottom: 1.875rem;
  }
}

.page-error .detail-section {
  min-height: 300px;
  padding-top: 2.5rem;
}
@media screen and (max-width: 768px) {
  .page-error {
    min-height: 58vh;
  }
}
@media screen and (max-width: 630px) {
  .page-error {
    min-height: 37vh;
  }
  .page-error .detail-section {
    padding-top: 1.25rem;
    min-height: 200px;
  }
}